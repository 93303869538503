import { FC, useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  Typography,
} from '@stenngroup/ui-kit';
import { EDevFeature } from './enums';
import { isDevFeatureEnabled } from './helpers/isDevFeatureEnabled';
import { toggleDevFeature } from './helpers/toggleDevFeature';

export const DevFeatureToggleContainer: FC = () => {
  const isLiveEnvironment = window._STENN_.ENVIRONMENT === 'live';
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  if (isLiveEnvironment) return null;

  return (
    <Stack
      sx={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        transform: 'rotate(-90deg)',
        zIndex: (t) => t.zIndex.fab,
      }}
    >
      <IconButton onClick={() => setIsDialogOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title="Dev features"
        maxWidth="sm"
        fullWidth={true}
      >
        <>
          <DialogTitle>
            <Typography.H5>Dev features</Typography.H5>
          </DialogTitle>
          <DialogContent>
            <Stack>
              {Object.values(EDevFeature).map((feature) => (
                <Switch
                  key={feature}
                  label={feature}
                  value={feature}
                  defaultChecked={isDevFeatureEnabled(feature)}
                  onChange={(event) => {
                    toggleDevFeature(feature, event.target.checked);
                  }}
                />
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Stack>
  );
};
