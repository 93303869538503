import * as Sentry from '@sentry/react';
import { useGetProfile } from '@rbf/api/profle/useGetProfile';
import { useAuth } from '@stenngroup/auth0-sdk';
import { useEffect } from 'react';

export const useSetSentryUser = (): void => {
  const { isAuthenticated } = useAuth();
  const { data: profile } = useGetProfile();

  useEffect(() => {
    if (isAuthenticated && profile) {
      Sentry.setUser({ id: profile.userId });
    } else {
      Sentry.setUser(null);
    }
  }, [isAuthenticated, profile]);
};
