export enum EQueryKey {
  GetProfile = 'getProfile',
  ReconnectPlatform = 'ReconnectPlatform',
  GetDashboardState = 'GetDashboardState',
  OfferInfo = 'OfferInfo',
  ConnectionsDetails = 'ConnectionsDetails',
  AvailablePlatforms = 'AvailablePlatforms',
  ConnectedPlatforms = 'ConnectedPlatforms',
  AccessTicket = 'AccessTicket',
}
