import { createUseProvidedContext } from '@rbf/common/context/createUseProvidedContext/createUseProvidedContext';
import { AnalyticsContext } from '@rbf/context/AnalyticsProvider/context';
import { IAnalyticsContext } from '@rbf/context/AnalyticsProvider/types';

export const useAnalyticsContext = createUseProvidedContext<IAnalyticsContext>(AnalyticsContext, 'AnalyticsContext');

export const useAnalytics = (): IAnalyticsContext['trackingData'] => {
  const { trackingData } = useAnalyticsContext();
  return trackingData;
};
