import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { ITrackingData } from './types';
import { processTrackingData } from './helpers/processTrackingData';
import { AnalyticsContext } from './context';
import { ELocalStorageKey } from '@rbf/storage-keys';
import { setTrackingFirstLandingUrl } from './cookies/cookiesRoutines';

interface IProps {
  children: ReactElement;
}

export const AnalyticsProvider = ({ children }: IProps): ReactElement => {
  const [trackingData, setTrackingData] = useState<ITrackingData | null>(null);
  const location = useLocation();

  const initialSearch = useRef(location.search);

  setTrackingFirstLandingUrl();
  if (initialSearch.current) {
    localStorage.setItem(ELocalStorageKey.ReferralURLSearch, initialSearch.current);
  }

  useEffect(() => {
    const data = processTrackingData();
    // setAnalyticsCookies(data);

    if (window._STENN_.ENVIRONMENT !== 'live') {
      // eslint-disable-next-line no-console
      console.log('CURRENT TRACKING DATA: ', data);
    }

    setTrackingData({
      landingUrl: data.landingUrl,
      referer: data.referer,
      webAnalytics: JSON.stringify(data.webAnalytics),
      techData: JSON.stringify(data.techData),
    });
  }, []);

  const contextValue = useMemo(() => {
    return { trackingData };
  }, [trackingData]);

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};
