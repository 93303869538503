import { FC } from 'react';
import { Stack } from '@mui/material';
import { ISettingsPageLayoutProps } from './types';
import { SettingsNavBar } from '@rbf/Pages/Dashboard/DashboardSettingsRoutes/components/SettingsNavBar';

export const SettingsPageLayout: FC<ISettingsPageLayoutProps> = ({ children }) => {
  return (
    <Stack flexGrow={1} direction="row">
      <Stack marginTop={8} padding={2} width={159}>
        <SettingsNavBar />
      </Stack>
      <Stack flex="1 1 auto">{children}</Stack>
    </Stack>
  );
};
