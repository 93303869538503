import { routes } from '@rbf/routes/config';
import {
  AppApplicationCloseReasonModel,
  AppApplicationStateModel,
  ApplicantInformationQuery,
  ApplicationStateModel,
  CurrentApplicationQuery,
} from '@rbf/__generated__/graphql/rbf/graphql';
import { isDevFeatureEnabled } from '@rbf/components/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@rbf/components/DevFeatureToggleContainer/enums';
import { captureException } from '@sentry/react';

export const getInitialRouteFBO = (
  currentApplication: CurrentApplicationQuery['currentApplication'],
  applicantInformation: ApplicantInformationQuery['applicantInformation'] | undefined,
  pathname: string
): string | null => {
  if (isDevFeatureEnabled(EDevFeature.DISABLE_AUTO_ROUTE)) {
    return null;
  }
  if (!currentApplication) return routes.onboarding.applyForFunding.path;

  if (currentApplication.stateV2 === AppApplicationStateModel.RequestedData) {
    if (!applicantInformation?.birthday || !applicantInformation.positionInCompany) {
      return routes.onboarding.completeApplicantInformation.path;
    }
    // do not change route for nested paths
    else if (!pathname.includes(routes.onboarding.conditionalOffer.path)) {
      return routes.onboarding.conditionalOffer.path;
    }
  } else if (currentApplication.stateV2 === AppApplicationStateModel.Submitted) {
    if (!applicantInformation?.birthday || !applicantInformation.positionInCompany) {
      return routes.onboarding.completeApplicantInformation.path;
    }
    // do not change route for nested paths
    else if (!pathname.includes(routes.onboarding.applicationSubmitted.path)) {
      return routes.onboarding.applicationSubmitted.path;
    }
  } else if (currentApplication.stateV2 === AppApplicationStateModel.Closed) {
    if (!currentApplication.closeReason) {
      captureException(new Error('closeReason is not defined but application is closed'));
      return routes.onboarding.fundingRejected.path;
    }
    if (currentApplication.closeReason === AppApplicationCloseReasonModel.Expired) {
      // do not change route for nested paths
      if (!pathname.includes(routes.onboarding.offerExpired.path)) {
        return routes.onboarding.offerExpired.path;
      }
    } else {
      // do not change route for nested paths
      if (!pathname.includes(routes.onboarding.fundingRejected.path)) {
        return routes.onboarding.fundingRejected.path;
      }
    }
  } else if (currentApplication.stateV2 === AppApplicationStateModel.Approved) {
    if (!applicantInformation?.birthday || !applicantInformation.positionInCompany) {
      return routes.onboarding.completeApplicantInformation.path;
    }
    // do not change route for nested paths
    if (
      isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING)
        ? !pathname.includes(routes.onboarding.offers.path)
        : !pathname.includes(routes.onboarding.getFunded.path)
    ) {
      return isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING)
        ? routes.onboarding.offers.path
        : routes.onboarding.getFunded.path;
    }
  } else {
    // change only if user is not on other states
    return routes.onboarding.applyForFunding.path;
  }
  return null;
};

export const getInitialRoute = (
  currentApplication: CurrentApplicationQuery['currentApplication'],
  applicantInformation: ApplicantInformationQuery['applicantInformation'] | undefined,
  pathname: string
): string | null => {
  if (isDevFeatureEnabled(EDevFeature.DISABLE_AUTO_ROUTE)) {
    return null;
  }
  if (!currentApplication) return routes.onboarding.applyForFunding.path;

  if (currentApplication.state === ApplicationStateModel.RequestedData) {
    if (!applicantInformation?.birthday || !applicantInformation.positionInCompany) {
      return routes.onboarding.completeApplicantInformation.path;
    }
    // do not change route for nested paths
    else if (!pathname.includes(routes.onboarding.conditionalOffer.path)) {
      return routes.onboarding.conditionalOffer.path;
    }
  } else if (currentApplication.state === ApplicationStateModel.Submitted) {
    if (!applicantInformation?.birthday || !applicantInformation.positionInCompany) {
      return routes.onboarding.completeApplicantInformation.path;
    }
    // do not change route for nested paths
    else if (!pathname.includes(routes.onboarding.applicationSubmitted.path)) {
      return routes.onboarding.applicationSubmitted.path;
    }
  } else if (currentApplication.state === ApplicationStateModel.Rejected) {
    // do not change route for nested paths
    if (!pathname.includes(routes.onboarding.fundingRejected.path)) {
      return routes.onboarding.fundingRejected.path;
    }
  } else if (currentApplication.state === ApplicationStateModel.Approved) {
    if (!applicantInformation?.birthday || !applicantInformation.positionInCompany) {
      return routes.onboarding.completeApplicantInformation.path;
    }
    // do not change route for nested paths
    else if (
      isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING)
        ? !pathname.includes(routes.onboarding.offers.path)
        : !pathname.includes(routes.onboarding.getFunded.path)
    ) {
      return isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING)
        ? routes.onboarding.offers.path
        : routes.onboarding.getFunded.path;
    }
  } else {
    // change only if user is not on other states
    return routes.onboarding.applyForFunding.path;
  }
  return null;
};
