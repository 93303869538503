import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import * as SC from './styles';

interface IProps {
  children: ReactElement;
}

export const LegalLayout = ({ children }: IProps): ReactElement => {
  return (
    <SC.Container>
      <Link to="/">
        <SC.FullScreenModalLogo />
      </Link>

      <SC.Wrapper>
        <SC.Content>{children}</SC.Content>
      </SC.Wrapper>
    </SC.Container>
  );
};
