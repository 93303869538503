/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
import { UTM_KEYS, TRACKING_SOURCE_TYPES, SEARCH_ENGINES, GTM_CONSENT_TYPES } from './constants';

import { IGAEvent, ITrackingData } from './types';
import { captureException } from '@sentry/react';
import { getCookiesConsent } from '@stenngroup/utilities';

const dataLayer = (window.dataLayer = window.dataLayer || []);

// Google
function gtag(): void {
  // eslint-disable-next-line prefer-rest-params
  dataLayer.push(arguments);
}

export const setGoogleAnalyticsUserData = (userId?: string): void => {
  if (window._STENN_.GTM_ID && userId) {
    dataLayer.push({ userId });
  }
};

export const sendEventToGA = ({ category, action, label, value }: IGAEvent & { value?: string }): void => {
  if (window._STENN_.GTM_ID && category && action) {
    const data = {
      event: 'ga-event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    };

    Object.keys(data).forEach((keyName) => {
      if (!data[keyName as keyof typeof data]) {
        delete data[keyName as keyof typeof data];
      }
    });

    dataLayer.push({ ...data });
  }
};

export const sendPageViewToGA = (url: string, tracking?: ITrackingData): void => {
  if (window._STENN_.GTM_ID) {
    dataLayer.push({
      event: 'pageview',
      page: url,
      tracking,
    });
  }
};

export const setDefaultGTMconsent = (isTrackingEnabled: boolean): void => {
  const consentValue = isTrackingEnabled ? GTM_CONSENT_TYPES.granted : GTM_CONSENT_TYPES.denied;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (gtag as any)('consent', 'default', {
    ad_storage: consentValue,
    analytics_storage: consentValue,
  });
};

export const updateGTMconsent = (isTrackingEnabled: boolean): void => {
  const consentValue = isTrackingEnabled ? GTM_CONSENT_TYPES.granted : GTM_CONSENT_TYPES.denied;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (gtag as any)('consent', 'update', {
    ad_storage: consentValue,
    analytics_storage: consentValue,
  });
};

export const getTrackingSourceType = (firstLandingSearchParams = {}, firstLandingReferrer = ''): string => {
  const hasCampaign = Object.keys(firstLandingSearchParams).some((item) => UTM_KEYS.includes(item));

  if (hasCampaign) {
    return TRACKING_SOURCE_TYPES.campaign;
  }

  if (!firstLandingReferrer || firstLandingReferrer.includes(window.location.hostname)) {
    return TRACKING_SOURCE_TYPES.direct;
  }

  let isFromSearch = false;

  for (let i = 0; i < SEARCH_ENGINES.length; i += 1) {
    if (firstLandingReferrer.includes(SEARCH_ENGINES[i])) {
      isFromSearch = true;
      break;
    }
  }

  if (isFromSearch) {
    return TRACKING_SOURCE_TYPES.search;
  }

  return TRACKING_SOURCE_TYPES.other;
};

export const loadHeapOnce = (): void => {
  if (!window._STENN_.HEAP_APP_ID) return;
  if (!window.heap) {
    captureException(new Error('window.heap must be defined if HEAP_APP_ID is defined — add script to index.html'));
    return;
  }
  if ('identify' in window.heap) return;
  window.heap.load(window._STENN_.HEAP_APP_ID);
};

export const initAnalyticScripts = (): void => {
  const cookiesConsent = getCookiesConsent(window._STENN_.ENVIRONMENT === 'live')();
  const isTrackingEnabled = cookiesConsent.performance;

  // Google Tag Manager (GTM)
  if (window._STENN_.GTM_ID) {
    setDefaultGTMconsent(isTrackingEnabled);
  }

  if (isTrackingEnabled) {
    loadHeapOnce();
  }
};

export const setHotjarIdentity = (userId: string | undefined): void => {
  if (!window.hj) return;
  window.hj('identify', userId || null, {});
};

export const setHeapIdentity = (userId: string | undefined): void => {
  if (!window._STENN_.HEAP_APP_ID) return;
  if (!window.heap) {
    captureException(new Error('window.heap must be defined if HEAP_APP_ID is defined — add script to index.html'));
    return;
  }

  if (!('identify' in window.heap)) {
    captureException(new Error('window.heap.load(window._STENN_.HEAP_APP_ID) must be called first'));
    return;
  }

  if (userId) {
    window.heap.identify(userId);
  } else {
    window.heap.resetIdentity();
  }
};

export const setHeapIdentityIfLoaded = (userId: string | undefined): void => {
  if (!window.heap) return;
  if (!('identify' in window.heap)) return;

  setHeapIdentity(userId);
};
