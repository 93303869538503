// init-sentty import must be first since Senty must be initialized before executing other modules
// added to package.json sideEffects to prevent tree shaking
import './init-sentry';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as Sentry from '@sentry/react';
import { initAnalyticScripts } from './context/AnalyticsProvider/utils';
import { ThemeModeSettingContextProviderWrapper } from './context/ThemeModeSettingContextProviderWrapper';

initAnalyticScripts();

createRoot(document.getElementById('root') as HTMLElement).render(
  <Sentry.ErrorBoundary>
    <StrictMode>
      <ThemeModeSettingContextProviderWrapper>
        <App />
      </ThemeModeSettingContextProviderWrapper>
    </StrictMode>
  </Sentry.ErrorBoundary>
);
