import { FC } from 'react';

export const StennLogo: FC = () => (
  <svg width="100%" height="100%" viewBox="0 0 63 44" fill="none">
    <path d="M38.6898 22.2485L49.4849 33.1269L38.6898 44.0002V22.2485Z" fill="white" />
    <path d="M44.9783 22.1643L33.9772 11.0809L44.9758 0L44.9783 22.1643Z" fill="white" />
    <path
      d="M59.4245 22.4102L53.024 15.9766H49.5098V28.6363H53.1196V22.1508L59.5717 28.6363H63V15.9766H59.4245V22.4102ZM24.0842 28.6363H34.0431V25.249H27.9515V23.8358H34.0431V20.787H27.9515V19.3219H34.0431V15.9766H24.0842V28.6363ZM19.1256 28.6363V19.3219H23.0591V15.9766H11.3689V19.3219H15.2608V28.6338H19.1256V28.6363ZM11.1826 25.123C11.4842 21.7703 8.30109 21.1873 5.85368 20.4238C5.47357 20.3077 4.14687 20.0014 4.19346 19.4924C4.23025 19.0773 5.0861 19.0304 5.72125 19.0699C7.66104 19.191 8.98529 19.737 10.3512 20.3398V16.7401C9.19128 16.1965 7.8376 15.8457 6.2436 15.7222H5.02234C2.27575 15.9544 0.198638 16.834 0 19.5344V19.7444C0.208447 22.1409 1.96921 23.018 4.06349 23.6382C4.68883 23.8235 7.06022 24.2731 7.03324 24.9526C7.02343 25.2614 6.48638 25.4615 6.07193 25.5035C3.63678 25.7456 1.43951 24.6289 0 23.7246V27.5344C3.39646 29.7358 10.7926 29.4492 11.1826 25.123Z"
      fill="white"
    />
  </svg>
);
