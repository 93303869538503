import { IAuth0Config } from '@stenngroup/auth0-sdk/lib/types';

export const createAuthConfig = (): IAuth0Config => ({
  domain: window._STENN_.AUTH_DOMAIN,
  clientID: window._STENN_.AUTH_CLIENT_ID,
  dbConnection: window._STENN_.AUTH_DATABASE_CONNECTION,
  scope: 'openid email offline_access profile', // DO NOT ADD ADDRESS HERE (it identifies as Hub app)
  audience: `https://${window._STENN_.AUTH_ORIGINAL_DOMAIN}/api/v2/`,
  redirectUri: window.location.origin,
  responseType: 'token id_token',
  prompt: 'none',
});
