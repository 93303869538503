import { graphql } from '@rbf/__generated__/graphql/rbf';

export const applicantInformationQueryDocument = graphql(/* GraphQL */ `
  query applicantInformation {
    applicantInformation {
      fullName
      birthday
      positionInCompany
      cellPhoneNumber
      cellPhoneCountryCode
      corporateEmail
      ssn
      isSoleOwnerOfTheCompany
      applicantAddress {
        address
        zipCode
        city
        countryId
        stateCode
        placeId
      }
    }
  }
`);
