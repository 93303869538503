import { useState, ReactElement } from 'react';
import { IThemeModeSettingContextProviderWrapperProps } from './types';
import { createLocalStorageThemeSettingStore } from '@rbf/common/context/ThemeModeSettingContext/store/localStorage';
import { ELocalStorageKey } from '@rbf/storage-keys';
import { EThemeModeSetting } from '@rbf/common/context/ThemeModeSettingContext/types';
import { ThemeModeSettingContextProvider } from '@rbf/common/context/ThemeModeSettingContext/ThemeModeSettingContextProvider/ThemeModeSettingContext';

const localStorageThemeSettingStore = createLocalStorageThemeSettingStore(ELocalStorageKey.ThemeModeSetting);

const defaultThemeSetting = EThemeModeSetting.Light;

export const ThemeModeSettingContextProviderWrapper = (
  props: IThemeModeSettingContextProviderWrapperProps
): ReactElement => {
  const { children } = props;
  const [themeModeSetting, setThemeMode] = useState(
    () => localStorageThemeSettingStore.retrieve() ?? defaultThemeSetting
  );

  return (
    <ThemeModeSettingContextProvider
      modeSetting={themeModeSetting}
      onModeSettingChange={(modeSetting) => {
        localStorageThemeSettingStore.put(modeSetting);
        setThemeMode(modeSetting);
      }}
    >
      {children}
    </ThemeModeSettingContextProvider>
  );
};
