import { IGraphQLError } from './types';

export const isErrorWithResponse = (error: Error): error is IGraphQLError =>
  // eslint-disable-next-line no-prototype-builtins
  error.hasOwnProperty('response');

/**
 * Check that value is instance of Error, it has additional properties set by graphql-request and contains endpoint errors
 *
 * If function returns true it means that the endpoint is available and the problem occurred during the execution of the request
 * and not due to infrastructure problems on the backend or network problems of the client
 */
export const isGraphQLEndpointError = (error: unknown): error is IGraphQLError =>
  error instanceof Error && isErrorWithResponse(error) && !!error.response.errors?.length;
