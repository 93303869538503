import { FC } from 'react';

import { SideNavItemV2, SideNavItemsListV2, SideNavSectionV2, SideNavSectionsV2, SideNavV2 } from '@stenngroup/ui-kit';
import { FolderRounded, GroupsRounded, LinkRounded, SpaceDashboardRounded, WalletRounded } from '@mui/icons-material';
import { routes } from '@rbf/routes/config';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { INavigationAsideBarItem } from './types';
import { ELocalStorageKey } from '@rbf/storage-keys';
import { isDevFeatureEnabled } from '@rbf/components/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@rbf/components/DevFeatureToggleContainer/enums';

export const SideBar: FC = () => {
  const { pathname } = useLocation();

  const navigationItems: INavigationAsideBarItem[] = [
    {
      title: 'Dashboard',
      icon: <SpaceDashboardRounded fontSize="inherit" />,
      path: routes.dashboard.path,
    },
  ];

  if (isDevFeatureEnabled(EDevFeature.FBO_DASHBOAD_WALLET)) {
    navigationItems.push({
      title: 'Wallet',
      icon: <WalletRounded fontSize="inherit" />,
      path: routes.dashboard.wallet.path,
    });
  }

  navigationItems.push(
    {
      title: 'Linked accounts',
      icon: <LinkRounded fontSize="inherit" />,
      path: routes.dashboard.connections.path,
    },
    {
      title: 'Documents',
      icon: <FolderRounded fontSize="inherit" />,
      path: routes.dashboard.documents.path,
    },
    {
      title: 'Team',
      icon: <GroupsRounded fontSize="inherit" />,
      path: routes.dashboard.teamMembers.path,
    }
  );

  return (
    <SideNavV2 localStorageKey={ELocalStorageKey.SideNavCollapsed}>
      <SideNavSectionsV2>
        <SideNavSectionV2>
          <SideNavItemsListV2>
            {navigationItems.map(({ title, icon, path }) => (
              <SideNavItemV2
                component={RouterLink}
                to={path}
                isActive={!!matchPath(path, pathname)}
                key={path}
                title={title}
                icon={icon}
              />
            ))}
          </SideNavItemsListV2>
        </SideNavSectionV2>
      </SideNavSectionsV2>
    </SideNavV2>
  );
};
