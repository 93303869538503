import Cookies, { CookieAttributes } from 'js-cookie';

import { BaseStorageService, IStorage } from './BaseStorageService';
import { determineStennCookiesDomain } from '@stenngroup/utilities';

const EXPIRE_DAYS = 30;
const cookiesDomain = determineStennCookiesDomain(window._STENN_.ENVIRONMENT === 'live');
const DEFAULT_COOKIES_OPTIONS = {
  expires: EXPIRE_DAYS,
  domain: cookiesDomain,
};

const setCookies = (key: string, value: string, options: CookieAttributes = {}): void => {
  Cookies.set(key, value, { ...DEFAULT_COOKIES_OPTIONS, ...options });
};

const storage: IStorage = {
  set: setCookies,
  get: (key: string) => Cookies.get(key) || null,
  has: (key: string) => Cookies.get(key) !== undefined,
  delete: (key) => {
    Cookies.remove(key, { domain: cookiesDomain });
  },
  getAll: () => Cookies.get(),
};

class CookiesStorageServiceBase extends BaseStorageService {
  setValueWithOptions = (key: string, value: string, options: CookieAttributes): void => {
    const property = this.getProperty(key);

    setCookies(property, value, options);
  };
}

export const CookiesStorageService = new CookiesStorageServiceBase(storage);
