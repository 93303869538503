import { graphql } from '@rbf/__generated__/graphql/rbf';

export const profileQueryDocument = graphql(/* GraphQL */ `
  query profile {
    profile {
      userId
      email
      emailIsConfirmed
      phoneNumberIsConfirmed
      fullName
      hasAcceptedLegalPolicies
      userMetadata {
        desiredLimit
        cellPhoneNumber
        cellPhoneCountryCode
        website
      }
      phoneNumber {
        number
        countryCode
      }
      phoneNumberIsConfirmed
    }
  }
`);
