import * as Sentry from '@sentry/react';
import { SentryFingerprintError } from './common/sentry/SentryFingerprintError';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { APP_VERSION } from './constants';

if (window._STENN_.RBF_SENTRY_DSN) {
  Sentry.init({
    dsn: window._STENN_.RBF_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ],
    tracePropagationTargets: [
      window._STENN_.API_RBF_PATH,
      window._STENN_.API_PUSHSENDER_PATH,
      window._STENN_.RBF_GRAPHQL_URL,
      window._STENN_.RBF_FINANCING_GRAPHQL_URL,
      window._STENN_.BANK_SERVICE_GRAPHQL_URL,
    ],
    tracesSampleRate: window._STENN_.ENVIRONMENT === 'live' ? 1 : 0.1,
    environment: window._STENN_.ENVIRONMENT,
    release: APP_VERSION,
    beforeSend: (event, hint) => {
      const exception = hint.originalException;

      if (exception instanceof Error) {
        if (exception.message.includes('Failed to fetch dynamically imported module')) {
          if (window.confirm('Reload is required for application to work properly. Do you want to reload now?')) {
            window.location.reload();
          }
          return null;
        }

        if (exception instanceof SentryFingerprintError) {
          const fingerprint = exception.message.replace(/\W+/g, '-');
          event.fingerprint = [fingerprint];
        }
      }

      return event;
    },
  });
}
