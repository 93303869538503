import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@stenngroup/auth0-sdk';
import { useGraphQLClient } from '@rbf/core/graphql/hooks/useGraphQLClient';
import { EGraphQLService } from '@rbf/core/graphql/enums';
import { useQuery } from 'react-query';
import { createGetQueryKey } from '@rbf/core/graphql/helpers/createGetQueryKey';
import { useLinearRouteInitializedOnceContext } from './useLinearRouteInitializedOnceContext';
import { applicantInformationQueryDocument } from '@rbf/core/graphql-documents/applicantInformationQueryDocument.rbf';
import { getInitialRoute, getInitialRouteFBO } from './helpers/getInitialRoute';
import { currentApplicationQueryDocument } from '@rbf/Pages/Onboarding/graphql-documents/currentApplicationQueryDocument.rbf';
import { isDevFeatureEnabled } from '@rbf/components/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@rbf/components/DevFeatureToggleContainer/enums';

export const useLinearRouteInitialization = (): void => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isInitialized, setInitialized } = useLinearRouteInitializedOnceContext();

  const graphQLClient = useGraphQLClient(EGraphQLService.rbf);

  const { data: currentApplication, isFetchedAfterMount: isCurrentApplicationFetchedAfterMount } = useQuery(
    [createGetQueryKey(EGraphQLService.rbf)(currentApplicationQueryDocument)],
    ({ signal }) => graphQLClient.request({ document: currentApplicationQueryDocument, signal }),
    {
      refetchOnMount: true,
      select: (res) => res.currentApplication,
    }
  );

  const isApplicantQueryEnabled = isAuthenticated && !!currentApplication;
  const { data: applicantInformation, isFetchedAfterMount: isSignatoryPersonFetchedAfterMount } = useQuery(
    [createGetQueryKey(EGraphQLService.rbf)(applicantInformationQueryDocument)],
    ({ signal }) => graphQLClient.request({ document: applicantInformationQueryDocument, signal }),
    {
      enabled: isApplicantQueryEnabled,
      refetchOnMount: true,
      select: (res) => res.applicantInformation,
    }
  );

  const isApplicantFetchedOrDisabled = isApplicantQueryEnabled ? isSignatoryPersonFetchedAfterMount : true;

  useEffect(() => {
    if (!isCurrentApplicationFetchedAfterMount || !isAuthenticated || !isApplicantFetchedOrDisabled || isInitialized) {
      return;
    }

    const getRoute = isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) ? getInitialRouteFBO : getInitialRoute;
    const initialRoute = getRoute(currentApplication, applicantInformation, pathname);

    if (initialRoute) {
      navigate(initialRoute, { replace: true });
    }

    setInitialized();
  }, [
    pathname,
    navigate,
    currentApplication,
    isAuthenticated,
    isApplicantFetchedOrDisabled,
    isCurrentApplicationFetchedAfterMount,
    isInitialized,
    setInitialized,
    applicantInformation,
  ]);
};
