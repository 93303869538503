import { ReactNode } from 'react';

export enum EListType {
  Decimal = 'decimal',
  LowerLatin = 'lower-latin',
}

export interface IListItem {
  title?: string;
  text?: ReactNode;
  list?: IList;
  component?: 'span' | 'p' | 'div';
}

interface IList {
  type?: EListType;
  items: IListItem[];
}

export interface ILegalLayoutListProps {
  items: IListItem[];
}
