import { Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useAuth } from '@stenngroup/auth0-sdk';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { createRef, FC, ReactNode, useEffect, useState } from 'react';
import { ERBFTestId } from '@rbf/testIds';
import artWork from './artwork.svg';
import { Button, StennLogo } from '@stenngroup/ui-kit';
// import { StennLogo } from '@rbf/components/StennLogo';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const TitleByState: Record<number, ReactNode> = {
  [1]: (
    <>
      Funding to match your growth.
      <br />
      <span style={{ color: '#07f7ce' }}>And boost it.</span>
    </>
  ),
  [2]: (
    <>
      Working capital that <span style={{ color: '#07f7ce' }}>works as hard as you.</span>
    </>
  ),
  [3]: (
    <>
      The banks back us.
      <br />
      <span style={{ color: '#07f7ce' }}>We back you.</span>
    </>
  ),
  [4]: (
    <>
      &quot;100% the best way to <span style={{ color: '#07f7ce' }}>help your brand grow</span>&quot;
    </>
  ),
  [5]: (
    <>
      Finance that moves as <span style={{ color: '#07f7ce' }}>fast</span> as your business
    </>
  ),
};

const DescriptionByState: Record<number, ReactNode> = {
  [1]: <>Stenn has funded over $20B to help small businesses thrive and grow. Now, it&apos;s your turn.</>,
  [2]: <>Get funding to supercharge your business – in 24 hours or less.</>,
  [3]: <>From Citi to HSBC, we&apos;re backed by the biggest banks in the world.</>,
  [4]: (
    <>
      &quot;I&apos;ve tried other growth capital partners and by far this has been the easiest and best to work
      with.&quot; - Love and Pebble
    </>
  ),
  [5]: <>Get fast access to capital with no limits. Then get on with running your business.</>,
};

export const SideBlock: FC<{ isLogoutButtonShown: boolean }> = (props) => {
  const { isLogoutButtonShown } = props;
  const { logout } = useAuth();
  const [activeText, setActiveText] = useState(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    let lastTime = Date.now();
    const interval = window.setInterval(() => {
      if (document.visibilityState !== 'visible') return;
      if (Date.now() - lastTime >= 15000) {
        setActiveText((prev) => (prev === Object.keys(TitleByState).length ? 1 : prev + 1));
        lastTime = Date.now();
      }
    }, 500);

    return () => window.clearInterval(interval);
  }, []);

  return (
    <Stack
      paddingY={2}
      sx={(t) => ({
        backgroundColor: '#011d24',
        backgroundImage: `url(${artWork})`,
        backgroundSize: 'cover',
        backgroundPositionY: '40%',
        [t.breakpoints.up('sm')]: {
          paddingY: 2,
        },
        [t.breakpoints.up('md')]: {
          flexGrow: 1,
          padding: 0,
        },
      })}
    >
      <Stack
        direction="row-reverse"
        alignItems="center"
        justifyContent="space-between"
        flexGrow={1}
        paddingX={5}
        gap={4}
        overflow="hidden"
        sx={(t) => ({
          [t.breakpoints.up('md')]: {
            padding: 0,
            alignItems: 'flex-start',
            flexDirection: 'column',
          },
        })}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          sx={(t) => ({
            order: 1,
            [t.breakpoints.up('md')]: {
              order: 0,
              paddingTop: 15,
              paddingX: 10,
              alignItems: 'flex-start',
              flexGrow: 1,
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            },
          })}
        >
          {/* https://github.com/StennGroup/stenn-ui/issues/784 */}
          {/* @TODO wait for ui-kit updates to make it white in light theme */}
          <StennLogo invertColors size={isSmallScreen ? 'xsmall' : 'small'} />
        </Stack>

        <Stack
          display="none"
          alignSelf="center"
          flexGrow={1}
          gap={1.15}
          sx={(t) => ({
            [t.breakpoints.up('md')]: {
              display: 'flex',
              paddingX: 10,
            },
          })}
          width="100%"
          height={180}
          position="relative"
        >
          <TransitionGroup component={null}>
            {Array.from({ length: Object.keys(TitleByState).length }).map((_, index) => {
              if (index + 1 !== activeText) return null;
              const ref = createRef<HTMLDivElement>();
              return (
                <CSSTransition
                  key={index + 1}
                  classNames={{
                    enter: 'slideEnter',
                    enterActive: 'slideEnterActive',
                    exit: 'slideExit',
                    exitActive: 'slideExitActive',
                  }}
                  nodeRef={ref}
                  addEndListener={(done) => ref.current!.addEventListener('transitionend', done, false)}
                >
                  <Box
                    ref={ref}
                    position="absolute"
                    width="60%"
                    sx={{
                      '&.slideEnter': {
                        transform: 'translateX(200%)',
                      },
                      '&.slideEnterActive': {
                        transform: 'translateX(0)',
                        transition: 'transform 850ms ease-in-out',
                      },
                      '&.slideExit': {
                        transform: 'translateX(0)',
                      },
                      '&.slideExitActive': {
                        transform: 'translateX(-200%)',
                        transition: 'transform 850ms ease-in-out',
                      },
                    }}
                  >
                    <Stack flexGrow={1} gap={1.15}>
                      <Typography color={(t) => t.palette.common.white} variant="caption">
                        REVENUE BASED FINANCE
                      </Typography>
                      <Typography color={(t) => t.palette.common.white} variant="h4">
                        {TitleByState[activeText]}
                      </Typography>
                      <Typography color={(t) => t.palette.common.white} variant="TextMd.Regular">
                        {DescriptionByState[activeText]}
                      </Typography>
                    </Stack>
                  </Box>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </Stack>
        <Box
          sx={(t) => ({
            [t.breakpoints.up('md')]: {
              paddingX: 10,
              paddingBottom: 10,
            },
          })}
        >
          {isLogoutButtonShown && (
            <Button
              color="primary"
              variant="link"
              linkColor="white"
              size={isSmallScreen ? 'xsmall' : 'small'}
              startIcon={<LogoutIcon sx={{ mr: 1, fill: (t) => t.palette.primary.contrast }} />}
              onClick={() => logout()}
              data-testid={ERBFTestId.OnboardingLayoutLogoutButton}
            >
              Log out
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
