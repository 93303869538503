export const GTM_CONSENT_TYPES = {
  denied: 'denied',
  granted: 'granted',
};

export const CLICK_IDS = {
  startReferralProgram: 'Start-referral-program',
  recommendStenn: 'Recommend-Stenn',
  startSellingInvoices: 'Start-selling-invoices',
  inviteSupplier: 'Invite-supplier',
  createDeal: 'Create-deal',
  copyReferralLink: 'Copy-referral-link',
  logInProfile: 'Log-in-profile',
  signUpProfile: 'Sign-up-profile',
  scheduleCallBack: 'Schedule-call-back',
  sendMessage: 'Send-message',
  invoiceAdded: 'invoice-added',
};

export const ANALYTICS_SEARCH_PARAMS = {
  dealSubmitted: 'dealSubmitted',
};

export const UTM_KEYS = ['utm_source', 'utm_campaign', 'pmt'];

export const TRACKING_SOURCE_TYPES = {
  direct: 'Direct',
  campaign: 'Campaign',
  search: 'Search',
  other: 'Other',
};

export const SEARCH_ENGINES = [
  'alexa.com',
  'ask.com',
  'baidu.com',
  'bing.com',
  'dogpile.com',
  'duckduckgo.com',
  'ecosia.org',
  'google.co.uk/search',
  'google.com/search',
  'google.ru/search',
  'rambler.ru',
  'search.aol.com',
  'search.auone.jp',
  'search.daum.net',
  'search.naver.com',
  'search.yahoo.com',
  'startpage.com',
  'yandex.com/search',
  'yandex.ru/search',
  'www.so.com',
];

export const GA_EVENTS = {
  preRegistrationPhone: {
    category: 'Registration',
    action: 'Submit',
    label: 'app-phone-pre-registration',
  },
  preRegistrationEmail: {
    category: 'Registration',
    action: 'Submit',
    label: 'app-email-pre-registration',
  },
  preRegistrationSocial: {
    category: 'Registration',
    action: 'Submit',
    label: 'app-social-pre-registration',
  },
};
