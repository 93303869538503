import { ComponentType, FC, ReactElement } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { routes } from '@rbf/routes/config';
import { useQuery } from 'react-query';
import { applicantInformationQueryDocument } from '@rbf/core/graphql-documents/applicantInformationQueryDocument.rbf';
import { createGetQueryKey } from '@rbf/core/graphql/helpers/createGetQueryKey';
import { useGraphQLClient } from '@rbf/core/graphql/hooks/useGraphQLClient';
import { EGraphQLService } from '@rbf/core/graphql/enums';
import { CircularProgress, Stack } from '@mui/material';

export const withCompletedApplicantInformation = <P extends object>(
  Component: ComponentType<P>,
  shouldBeCompleted: boolean
): FC<P> => {
  return function WithCompletedApplicantInformation(props: P): ReactElement | null {
    const { pathname } = useLocation();
    const graphQLClient = useGraphQLClient(EGraphQLService.rbf);
    const { data: applicantInformation } = useQuery(
      [createGetQueryKey(EGraphQLService.rbf)(applicantInformationQueryDocument)],
      ({ signal }) => graphQLClient.request({ document: applicantInformationQueryDocument, signal }),
      {
        refetchOnMount: true,
        select: (res) => res.applicantInformation,
      }
    );

    if (!applicantInformation) {
      return (
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      );
    }
    const completed = !!applicantInformation?.birthday && !!applicantInformation.positionInCompany;

    let redirectPath: string | undefined;
    if (shouldBeCompleted) {
      if (!completed) {
        redirectPath = routes.invitation.completeApplicantInformation.path;
      }
    } else {
      if (completed) {
        redirectPath = routes.dashboard.path;
      }
    }

    if (redirectPath && !pathname.includes(redirectPath)) {
      return <Navigate to={redirectPath} />;
    }

    return <Component {...props} />;
  };
};
