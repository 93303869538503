import { ComponentType, FC, ReactElement } from 'react';

import { Navigate } from 'react-router-dom';

import { useAuth } from '@stenngroup/auth0-sdk';
import { routes } from '@rbf/routes/config';
import { useGetProfile } from '@rbf/api/profle/useGetProfile';
import { UserContextProvider } from '@rbf/context/UserContext/UserContextProvider';

export const withAuthenticated = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithAuthenticated(props: P): ReactElement | null {
    const { isAuthenticated } = useAuth();
    const { data: profile } = useGetProfile();

    if (!isAuthenticated) {
      return <Navigate to={routes.auth.login.path} />;
    }

    if (!profile) return null;

    return (
      <UserContextProvider id={profile.userId} name={profile.fullName} email={profile.email}>
        <Component {...props} />
      </UserContextProvider>
    );
  };
};
