import { graphql } from '@rbf/__generated__/graphql/rbf';

export const offerActivationQueryDocument = graphql(/* GraphQL */ `
  query offerActivation($offerId: UUID!) {
    offerActivation(offerId: $offerId) {
      __typename
      ... on FboOfferActivationModel {
        applicationId
        state
        offer {
          id
          state
          closeReason
          offerTerms {
            amount
            feesRate
            paymentCadence
            repaymentRate
            tenor
            daysValidAfterIssue
            maxPaymentAmount
            expiresAt
          }
          requiresStennWallet
        }
        stennWalletActivation {
          state
          businessVerification {
            submitted
            mccIndustryCode
            certificateOfIncorporation {
              fileId
              fileName
            }
          }
          verifiableBusinessPersons {
            id
            firstName
            lastName
            jobTitle
            nationalityCode
            rolesInCompany
            ssn
            personalId {
              frontPage {
                fileId
                fileName
              }
              backPage {
                fileId
                fileName
              }
            }
            driverLicense {
              frontPage {
                fileId
                fileName
              }
              backPage {
                fileId
                fileName
              }
            }
            passport {
              mainPage {
                fileId
                fileName
              }
            }
          }
          agreedToDataUsage
          agreedToTermsAndConditions
        }
        initialTransactionModel {
          details {
            amount
            minLimit
            maxLimit
          }
          canBeEdited
        }
        achConnection {
          id
          state
          verificationUrl
          clientSecret
          accountNumber
        }
        legalDocumentsPackageModel {
          isFullySigned
          signatures {
            fullName
            email
            isSigned
          }
        }
      }
      ... on RbfOfferActivationModel {
        applicationId
        state
        offer {
          id
          state
          closeReason
          offerTerms {
            amount
            feesRate
            paymentCadence
            repaymentRate
            tenor
            daysValidAfterIssue
            maxPaymentAmount
            expiresAt
          }
          requiresStennWallet
        }
        initialTransactionModel {
          details {
            amount
            minLimit
            maxLimit
          }
          canBeEdited
        }
        achConnection {
          id
          state
          verificationUrl
          clientSecret
          accountNumber
        }
        legalDocumentsPackageModel {
          isFullySigned
          signatures {
            fullName
            email
            isSigned
          }
        }
      }
    }
  }
`);
