import { Button, Stack } from '@mui/material';
import { routes } from '@rbf/routes/config';
import { ERBFTestId } from '@rbf/testIds';
import { FC } from 'react';
import { matchPath, useLocation, Link as RouterLink } from 'react-router-dom';

export const SettingsNavBar: FC = () => {
  const { pathname } = useLocation();

  return (
    <Stack>
      <Button
        variant={matchPath(routes.dashboard.settings.account.path, pathname) ? 'contained' : 'outlined'}
        size="small"
        component={RouterLink}
        to={routes.dashboard.settings.account.path}
        data-testid={ERBFTestId.DashboardSettingsLayoutAccountPageLink}
      >
        Account
      </Button>
    </Stack>
  );
};
