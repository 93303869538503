import { RevenueRange } from '@rbf/__generated__/graphql/rbf/graphql';
import { IGAEvent } from './context/AnalyticsProvider/types';
import { BusinessType } from '@rbf/__generated__/graphql/rbf/graphql';

export const BUSINESS_TYPE_OPTIONS: Record<BusinessType, string> = {
  [BusinessType.Ecommerce]: 'eCommerce',
  [BusinessType.Subscription]: 'SaaS / Subscription',
  [BusinessType.HybridRetail]: 'Hybrid retail (online and offline)',
  [BusinessType.OfflineRetail]: 'Offline retail',
  [BusinessType.OfflineServices]: 'Offline services',
  [BusinessType.HospitalityAndTourism]: 'Hospitality and Tourism',
  [BusinessType.Logistics]: 'Logistics',
  [BusinessType.Other]: 'Other',
};

export const USA_COUNTRY_ID = 'US';

export enum EPlatformFlow {
  Commerce = 'storeConnectionInfo',
  Accounting = 'accountingConnectionInfo',
  Banking = 'bankingConnectionInfo',
}

export const EMAIL_OTP_TIMEOUT = 40;
export const PHONE_OTP_TIMEOUT = 40;

export const PASSWORD_SETTED_REDIRECT_TIMEOUT = 3;
export const MIN_TRANSACTION_LIMIT = 10_000;

export const PASSWORD_RULES_DESCRIPTIONS = {
  minLength: 'At least 8 characters',
  minDigits: '1 number',
  minUpperCase: '1 uppercase letter',
  minLowerCase: '1 lowercase letter',
} as const;

export const PAGE_WITH_EMPTY_CONTENT = 'data:text/plain';

export enum ERbfGaEvent {
  rbfSignupButtonClick = 'rbfSignupButtonClick',
  rbfSignupValidationFailedInvalidEmail = 'rbfSignupValidationFailedEmail',
  rbfSignupValidationFailedInvalidName = 'rbfSignupValidationFailedInvalidName',
  rbfSignupValidationFailedNameLength = 'rbfSignupValidationFailedNameLength',
}

export const RBF_GA_EVENTS: Record<ERbfGaEvent, IGAEvent> = {
  [ERbfGaEvent.rbfSignupButtonClick]: {
    category: 'Button',
    action: 'Click',
    label: 'rbf-signup-button-click',
  },
  [ERbfGaEvent.rbfSignupValidationFailedInvalidEmail]: {
    category: 'SignUpFormSubmitValidatonFailure',
    action: 'invalid-email',
    label: 'rbf-signup-form-submit-validation-failure-invalid-email',
  },
  [ERbfGaEvent.rbfSignupValidationFailedInvalidName]: {
    category: 'SignUpFormSubmitValidatonFailure',
    action: 'invalid-name',
    label: 'rbf-signup-form-submit-validation-failure-invalid-name',
  },
  [ERbfGaEvent.rbfSignupValidationFailedNameLength]: {
    category: 'SignUpFormSubmitValidatonFailure',
    action: 'invalid-name-length',
    label: 'rbf-signup-form-submit-validation-failure-invalid-name-length',
  },
};

export const REVENUE_OPTIONS: Record<RevenueRange, string> = {
  [RevenueRange.RevenueXxs]: 'Less than $5,000',
  [RevenueRange.RevenueXs]: '$5,000 - $15,999',
  [RevenueRange.RevenueS]: '$16,000 - $99,999',
  [RevenueRange.RevenueM]: '$100,000 - $249,999',
  [RevenueRange.RevenueL]: '$250,000 - $499,999',
  [RevenueRange.RevenueXl]: '$500,000 or more',
};

export const AGE_OF_MAJORITY = 18;

export const APP_VERSION =
  (document.querySelector('meta[name="build-version"]') as HTMLMetaElement)?.content ?? 'unknown';
