export const enum ERBFTestId {
  AddressAutocompleteListItem = 'AddressAutocompleteListItem',
  DashboardPaymentsPageSignatorySignButton = 'DashboardPaymentsPageSignatorySignButton',
  DashboardMakeFirstDrawAlertDrawButton = 'DashboardMakeFirstDrawAlertDrawButton',
  DashboardFullyRepaidAlertMakeDrawButton = 'DashboardFullyRepaidAlertMakeDrawButton',
  DashboardConnectionDisconnectedAlertButton = 'DashboardConnectionDisconnectedAlertButton',
  DashboardConnectionsReconnectConfirmationModal = 'DashboardConnectionsReconnectConfirmationModal',
  DashboardMakeDrawModalAmountInput = 'DashboardMakeDrawModalAmountInput',
  DashboardMakeDrawModalSubmitButton = 'DashboardMakeDrawModalSubmitButton',
  DashboardMakeDrawModalPurchasedAmount = 'DashboardMakeDrawModalPurchasedAmount',
  DashboardMakeDrawModalDiscountRate = 'DashboardMakeDrawModalDiscountRate',
  DashboardMakeDrawModalPurchasePrice = 'DashboardMakeDrawModalPurchasePrice',
  DashboardMakeDrawModalPaymentRate = 'DashboardMakeDrawModalPaymentRate',
  DashboardMakeDrawModalMaximumPaymentAmount = 'DashboardMakeDrawModalMaximumPaymentAmount',
  DashboardLimitSummaryCardMakeDrawButton = 'DashboardLimitSummaryCardMakeDrawButton',
  DashboardSettingsLayoutAccountPageLink = 'DashboardSettingsLayoutAccountPageLink',
  DashboardDrawsCardSeeAllClosedDrawsButton = 'DashboardDrawsCardSeeAllClosedDrawsButton',
  DashboardConnectionsBankingBlockLinkConnectionModal = 'DashboardConnectionsBankingBlockLinkConnectionModal',
  DashboardConnectionsCommerceBlockLinkConnectionModal = 'DashboardConnectionsCommerceBlockLinkConnectionModal',
  DashboardConnectionsAccountingBlockLinkConnectionModal = 'DashboardConnectionsAccountingBlockLinkConnectionModal',
  DashboardACHSectionReconnectButton = 'DashboardACHSectionReconnectButton',
  DashboardACHSectionTryAgainButton = 'DashboardACHSectionTryAgainButton',
  DashboardACHSectionTryAnotherAccountButton = 'DashboardACHSectionTryAnotherAccountButton',
  DashboardACHSectionContinueButton = 'DashboardACHSectionContinueButton',
  DashboardACHSectionAuthorizeButton = 'DashboardACHSectionAuthorizeButton',
  DashboardACHSectionDismissSuccessAlertButton = 'DashboardACHSectionDismissSuccessAlertButton',
  SignUpFooterReadMoreLink = 'SignUpFooterReadMoreLink',
  SignUpTermsOfUseLink = 'SignUpTermsOfUseLink',
  SignUpPrivacyPolicyLink = 'SignUpPrivacyPolicyLink',
  SignUpEmailConfirmationCodeInput = 'SignUpEmailConfirmationCodeInput',
  SignUpEmailConfirmationResendButton = 'SignUpEmailConfirmationResendButton',
  SignUpEmailConfirmationNextButton = 'SignUpEmailConfirmationNextButton',
  SignUpPhoneConfirmationCodeInput = 'SignUpPhoneConfirmationCodeInput',
  SignUpPhoneConfirmationResendButton = 'SignUpPhoneConfirmationResendButton',
  SignUpPhoneConfirmationNextButton = 'SignUpPhoneConfirmationNextButton',
  SignUpPhoneNumberInput = 'SignUpPhoneNumberInput',
  SignUpDesiredLimitInput = 'SignUpDesiredLimitInput',
  SignUpSignUpButton = 'SignUpSignUpButton',
  InvitationAcceptTermsOfUseLink = 'InvitationAcceptTermsOfUseLink',
  InvitationAcceptPrivacyPolicyLink = 'InvitationAcceptPrivacyPolicyLink',
  InvitationAcceptLogInButton = 'InvitationAcceptLogInButton',
  InvitationAcceptSignUpButton = 'InvitationAcceptSignUpButton',
  InvitationAcceptCorporateEmailField = 'InvitationAcceptCorporateEmailField',
  InvitationAcceptFullNameInput = 'InvitationAcceptFullNameInput',
  InvitationAcceptPasswordInput = 'InvitationAcceptPasswordInput',
  InvitationAcceptV4FirstNameInput = 'InvitationAcceptV4FirstNameInput',
  InvitationAcceptV4LastNameInput = 'InvitationAcceptV4LastNameInput',
  InvitationAcceptV4CorporateEmailField = 'InvitationAcceptV4CorporateEmailField',
  InvitationAcceptV4PasswordInput = 'InvitationAcceptV4PasswordInput',
  InvitationAcceptV4SignUpButton = 'InvitationAcceptV4SignUpButton',
  InvitationAcceptV4PrivacyPolicyLink = 'InvitationAcceptV4PrivacyPolicyLink',
  InvitationAcceptV4TermsOfUseLink = 'InvitationAcceptV4TermsOfUseLink',
  InvitationAcceptV4LogInButton = 'InvitationAcceptV4LogInButton',
  OnboardingLayoutMainContent = 'OnboardingLayoutMainContent',
  AuthLoginSignUpButton = 'AuthLoginSignUpButton',
  AuthLoginForgotPasswordButton = 'AuthLoginForgotPasswordButton',
  AuthLoginSubmitButton = 'AuthLoginSubmitButton',
  AuthLoginGoogleButton = 'AuthLoginGoogleButton',
  SignUpLoginButton = 'SignUpLoginButton',
  SignUpGoogleButton = 'SignUpGoogleButton',
  SignUpLoginLinkInErrorAlert = 'SignUpLoginLinkInErrorAlert',
  ForgotPasswordMainV3EmailInput = 'ForgotPasswordMainV3EmailInput',
  ForgotPasswordMainV3ResetPasswordButton = 'ForgotPasswordMainV3ResetPasswordButton',
  ForgotPasswordEmailConfirmationV3CodeInput = 'ForgotPasswordEmailConfirmationV3CodeInput',
  ForgotPasswordEmailConfirmationV3ResendCodeButton = 'ForgotPasswordEmailConfirmationV3ResendCodeButton',
  ForgotPasswordChangePasswordV3NewPasswordInput = 'ForgotPasswordChangePassworV3dNewPasswordInput',
  ForgotPasswordChangePasswordV3ConfirmNewPasswordInput = 'ForgotPasswordChangePasswordConfirmV3NewPasswordInput',
  ForgotPasswordChangePasswordV3ChangePasswordButton = 'ForgotPasswordChangePasswordV3ChangePasswordButton',
  ForgotPasswordChangePasswordV3LoginButton = 'ForgotPasswordChangePasswordV3LoginButton',
  OnboardingApplicationSubmittedConnectionsPageBackButton = 'OnboardingApplicationSubmittedConnectionsPageBackButton',
  OnboardingApplicationSubmittedConnectionsPageInviteTeamMemberButton = 'OnboardingApplicationSubmittedConnectionsPageInviteTeamMemberButton',
  OnboardingApplicationSubmittedAfterConditionalOfferViewConnectionsLink = 'OnboardingApplicationSubmittedAfterConditionalOfferViewConnectionsLink',
  OnboardingApplicationSubmittedDiscountRateAccordion = 'OnboardingApplicationSubmittedDiscountRateAccordion',
  OnboardingApplicationSubmittedPaymentRateAccordion = 'OnboardingApplicationSubmittedPaymentRateAccordion',
  OnboardingApplicationSubmittedPaymentAmountAccordion = 'OnboardingApplicationSubmittedPaymentAmountAccordion',
  OnboardingStopFlowModalOkButton = 'OnboardingStopFlowModalOkButton',
  OnboardingSorryModalOkButton = 'OnboardingSorryModalOkButton',
  OnboardingConnectionsPageBackButton = 'OnboardingConnectionsPageBackButton',
  OnboardingConnectionsPageShowReasonsModalButton = 'OnboardingConnectionsPageShowReasonsModalButton',
  OnboardingConnectionsPageInviteTeamMemberButton = 'OnboardingConnectionsPageInviteTeamMemberButton',
  OnboardingConnectionsPageNextButton = 'OnboardingConnectionsPageNextButton',
  OnboardingConnectionsReasonsModal = 'OnboardingConnectionsReasonsModal',
  OnboardingApplyForFundingConfirmationModalSubmitButton = 'OnboardingApplyForFundingConfirmationModalSubmitButton',
  OnboardingApplyForFundingConfirmationModalCancelButton = 'OnboardingApplyForFundingConfirmationModalCancelButton',
  OnboardingApplyForFundingConfirmationModal = 'OnboardingApplyForFundingConfirmationModal',
  OnboardingApplyForFundingReconnectConfirmationModal = 'OnboardingApplyForFundingReconnectConfirmationModal',
  OnboardingApplyForFundingAbortConfirmationModal = 'OnboardingApplyForFundingAbortConfirmationModal',
  OnboardingFundingRejectedTermsOfUseLink = 'OnboardingFundingRejectedTermsOfUseLink',
  OnboardingFundingRejectedContactSupportLink = 'OnboardingFundingRejectedContactSupportLink',
  OnboardingGetFundedEditConnectionsLink = 'OnboardingGetFundedEditConnectionsLink',
  OnboardingGetFundedSignLegalAgreementButton = 'OnboardingGetFundedSignLegalAgreementButton',
  OnboardingGetFundedGetFundedButton = 'OnboardingGetFundedGetFundedButton',
  OnboardingGetFundedConnectionsPageBackButton = 'OnboardingGetFundedConnectionsPageBackButton',
  OnboardingGetFundedConnectionsPageInviteTeamMemberButton = 'OnboardingGetFundedConnectionsPageInviteTeamMemberButton',
  OnboardingGetFundedAcceptOfferButton = 'OnboardingGetFundedAcceptOfferButton',
  OnboardingGetFundedInviteTeamMemberButton = 'OnboardingGetFundedInviteTeamMemberButton',
  OnboardingGetFundedAcceptModal = 'OnboardingGetFundedAcceptModal',
  OnboardingGetFundedDeclineModal = 'OnboardingGetFundedDeclineModal',
  OnboardingGetFundedDiscountRateAccordion = 'OnboardingGetFundedDiscountRateAccordion',
  OnboardingGetFundedPaymentRateAccordion = 'OnboardingGetFundedPaymentRateAccordion',
  OnboardingGetFundedMaximumPaymentAmountAccordion = 'OnboardingGetFundedMaximumPaymentAmountAccordion',
  OnboardingGetFundedInitiateTransactionPurchasedAmount = 'OnboardingGetFundedInitiateTransactionPurchasedAmount',
  OnboardingGetFundedInitialTransactionNewTransactionButton = 'OnboardingGetFundedInitialTransactionNewTransactionButton',
  OnboardingGetFundedInitialTransactionHideDetailsButton = 'OnboardingGetFundedInitialTransactionHideDetailsButton',
  OnboardingGetFundedInitialTransactionViewDetailsButton = 'OnboardingGetFundedInitialTransactionViewDetailsButton',
  OnboardingGetFundedInitialTransactionEditButton = 'OnboardingGetFundedInitialTransactionEditButton',
  OnboardingGetFundedAuthorizeButton = 'OnboardingGetFundedAuthorizeButton',
  OnboardingGetFundedInitiateTransactionDiscountRate = 'OnboardingGetFundedInitiateTransactionDiscountRate',
  OnboardingGetFundedInitiateTransactionPurchasePrice = 'OnboardingGetFundedInitiateTransactionPurchasePrice',
  OnboardingGetFundedInitiateTransactionPaymentRate = 'OnboardingGetFundedInitiateTransactionPaymentRate',
  OnboardingGetFundedInitiateTransactionMaximumPaymentAmount = 'OnboardingGetFundedInitiateTransactionMaximumPaymentAmount',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModal = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModal',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalAmountInput = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalAmountInput',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalPurchasedAmount = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalPurchasedAmount',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalDiscountRate = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalDiscountRate',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalPurchasePrice = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalPurchasePrice',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalPaymentRate = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalPaymentRate',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalMaximumPaymentAmount = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalMaximumPaymentAmount',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalSubmitButton = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalSubmitButton',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalSaveChangesButton = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalSaveChangesButton',
  OnboardingGetFundedInitiateTransactionCustomizeDrawModalCancelButton = 'OnboardingGetFundedInitiateTransactionCustomizeDrawModalCancelButton',
  OnboardingGetFundedACHProvideModalCancelButton = 'OnboardingGetFundedACHProvideModalCancelButton',
  OnboardingGetFundedACHProvideModalAuthorizeButton = 'OnboardingGetFundedACHProvideModalAuthorizeButton',
  OnboardingGetFundedACHBStatusBlockTryAnotherAccountButton = 'OnboardingGetFundedACHBStatusBlockTryAnotherAccountButton',
  OnboardingGetFundedACHBStatusBlockContinueButton = 'OnboardingGetFundedACHBStatusBlockContinueButton',
  OnboardingConditionalOfferViewConnectionsLink = 'OnboardingConditionalOfferViewConnectionsLink',
  OnboardingConditionalOfferNextButton = 'OnboardingConditionalOfferNextButton',
  OnboardingConditionalOfferInviteTeamMemberButton = 'OnboardingConditionalOfferInviteTeamMemberButton',
  OnboardingConditionalOfferDiscountRateAccordion = 'OnboardingConditionalOfferDiscountRateAccordion',
  OnboardingConditionalOfferPaymentRateAccordion = 'OnboardingConditionalOfferPaymentRateAccordion',
  OnboardingConditionalOfferPaymentAmountAccordion = 'OnboardingConditionalOfferPaymentAmountAccordion',
  OnboardingRequestedDataSubmitButton = 'OnboardingRequestedDataSubmitButton',
  OnboardingRequestedDataDiscountRateSection = 'OnboardingRequestedDataDiscountRateSection',
  OnboardingRequestedDataPaymentRateAccordion = 'OnboardingRequestedDataPaymentRateAccordion',
  OnboardingRequestedDataPaymentAmountAccordion = 'OnboardingRequestedDataPaymentAmountAccordion',
  OnboardingRequestedDataViewConnectionsButton = 'OnboardingRequestedDataViewConnectionsButton',
  OnboardingConditionalOfferConnectionsPageBackButton = 'OnboardingConditionalOfferConnectionsPageBackButton',
  OnboardingConditionalOfferConnectionsPageInviteTeamMemberButton = 'OnboardingConditionalOfferConnectionsPageInviteTeamMemberButton',
  OnboardingConditionalOfferReconnectConfirmationModal = 'OnboardingConditionalOfferReconnectConfirmationModal',
  OnboardingConditionalOfferAbortConfirmationModal = 'OnboardingConditionalOfferAbortConfirmationModal',
  OnboardingConditionalOfferReconnectConnectionButton = 'OnboardingConditionalOfferReconnectConnectionButton',
  OnboardingConditionalOfferSubmittedAfterConditionInviteTeamMemberButton = 'OnboardingConditionalOfferSubmittedAfterConditionInviteTeamMemberButton',
  OnboardingInviteTeamMemberAlertCardButton = 'OnboardingInviteTeamMemberAlertCardButton',
  OnboardingConnectionsSectionViewLink = 'OnboardingConnectionsSectionViewLink',
  OnboardingOffersPageFBODiscountRateSection = 'OnboardingOffersPageFBODiscountRateSection',
  OnboardingOffersPageFBOPaymentRateSection = 'OnboardingOffersPageFBOPaymentRateSection',
  OnboardingOffersPageFBOPaymentFrequencySection = 'OnboardingOffersPageFBOPaymentFrequencySection',
  OnboardingOffersPageRBFDiscountRateSection = 'OnboardingOffersPageRBFDiscountRateSection',
  OnboardingOffersPageRBFPaymentRateSection = 'OnboardingOffersPageRBFPaymentRateSection',
  OnboardingOffersPageRBFMaxPaymentsSection = 'OnboardingOffersPageRBFMaxPaymentsSection',
  OnboardingOfferPageVerifyBusinessSectionVerifyButton = 'OnboardingOfferPageVerifyBusinessSectionVerifyButton',
  OnboardingOfferPageVerifyBusinessSectionEditButton = 'OnboardingOfferPageVerifyBusinessSectionEditButton',
  OnboardingOfferPageVerifyTeamMembersSectionInviteTeamMemberButton = 'OnboardingOfferPageVerifyTeamMembersSectionInviteTeamMemberButton',
  OnboardingOfferPageVerifyTeamMembersSectionVerifyButton = 'OnboardingOfferPageVerifyTeamMembersSectionVerifyButton',
  OnboardingOfferPageVerifyTeamMembersSectionEditVerificationButton = 'OnboardingOfferPageVerifyTeamMembersSectionEditVerificationButton',
  InviteTeamMemberModalFullNameInput = 'TeamMembersDialogFullNameInput',
  InviteTeamMemberModalCorporateEmailInput = 'InviteTeamMembersModalCorporateEmailInput',
  InviteTeamMembersModalSendButton = 'InviteTeamMembersModalSendButton',
  InviteTeamMemberModalMemberMoreOptionButton = 'InviteTeamMemberModalMemberMoreOptionButton',
  InviteTeamMemberModalMemberResendInviteOption = 'InviteTeamMemberModalMemberResendInviteOption',
  DashboardTeamMembersInviteTeamMemberButton = 'DashboardTeamMembersInviteTeamMemberButton',
  DashboardTeamMembersResendInvite = 'DashboardTeamMembersResendInvite',
  DashboardCustomiseDrawModal = 'DashboardCustomiseDrawModal',
  OnboardingDocumentsBackLink = 'OnboardingDocumentsBackLink',
  OnboardingConnectionsPageReconnectConfirmationReconnectButton = 'OnboardingConnectionsPageReconnectConfirmationReconnectButton',
  OnboardingConnectionsPageReconnectConfirmationCancelButton = 'OnboardingConnectionsPageReconnectConfirmationCancelButton',
  OnboardingConnectionsPageAbortConfirmationAbortButton = 'OnboardingConnectionsPageAbortConfirmationAbortButton',
  OnboardingConnectionsPageAbortConfirmationCancelButton = 'OnboardingConnectionsPageAbortConfirmationCancelButton',
  OnboardingConnectionsPageConnectionsSelectCloseButton = 'OnboardingConnectionsPageConnectionsSelectCloseButton',
  OnboardingConnectionsPageConnectionsSelectCommerceSandbox = 'OnboardingConnectionsPageConnectionsSelectCommerceSandbox',
  OnboardingConnectionsPageConnectionsSelectBankingSandbox = 'OnboardingConnectionsPageConnectionsSelectBankingSandbox',
  OnboardingConnectionsPageConnectionsSelectSandbox = 'OnboardingConnectionsPageConnectionsSelectSandbox',
  OnboardingConnectionsPageConnectionsSelectWooCommerce = 'OnboardingConnectionsPageConnectionsSelectWooCommerce',
  OnboardingConnectionsPageConnectionsSelectBigCommerce = 'OnboardingConnectionsPageConnectionsSelectBigCommerce',
  OnboardingConnectionsPageConnectionsSelectPrestaShop = 'OnboardingConnectionsPageConnectionsSelectPrestaShop',
  OnboardingConnectionsPageConnectionsSelectPandle = 'OnboardingConnectionsPageConnectionsSelectPandle',
  OnboardingConnectionsPageConnectionsSelectPlaid = 'OnboardingConnectionsPageConnectionsSelectPlaid',
  OnboardingConnectionsPageConnectionsSelectShopify = 'OnboardingConnectionsPageConnectionsSelectShopify',
  OnboardingConnectionsPageConnectionsSelectSageBusinessCloudAccounting = 'OnboardingConnectionsPageConnectionsSelectSageBusinessCloudAccounting',
  OnboardingConnectionsPageConnectionsSelectFreeAgent = 'OnboardingConnectionsPageConnectionsSelectFreeAgent',
  OnboardingConnectionsPageConnectionsSelectClearBooks = 'OnboardingConnectionsPageConnectionsSelectClearBooks',
  OnboardingConnectionsPageConnectionsSelectKashFlow = 'OnboardingConnectionsPageConnectionsSelectKashFlow',
  OnboardingConnectionsPageConnectionsSelectQuickBooksOnline = 'OnboardingConnectionsPageConnectionsSelectQuickBooksOnline',
  OnboardingConnectionsPageConnectionsSelectSage50UK = 'OnboardingConnectionsPageConnectionsSelectSage50UK',
  OnboardingConnectionsPageConnectionsSelectQuickBooksDesktop = 'OnboardingConnectionsPageConnectionsSelectQuickBooksDesktop',
  OnboardingConnectionsPageConnectionsSelectZohoBooks = 'OnboardingConnectionsPageConnectionsSelectZohoBooks',
  OnboardingConnectionsPageConnectionsSelectWave = 'OnboardingConnectionsPageConnectionsSelectWave',
  OnboardingConnectionsPageConnectionsSelectDynamics365BusinessCentral = 'OnboardingConnectionsPageConnectionsSelectDynamics365BusinessCentral',
  OnboardingConnectionsPageConnectionsSelectOracleNetSuite = 'OnboardingConnectionsPageConnectionsSelectOracleNetSuite',
  OnboardingConnectionsPageConnectionsSelectChargebee = 'OnboardingConnectionsPageConnectionsSelectChargebee',
  OnboardingConnectionsPageConnectionsSelectRecurly = 'OnboardingConnectionsPageConnectionsSelectRecurly',
  OnboardingConnectionsPageConnectionsSelectStripe = 'OnboardingConnectionsPageConnectionsSelectStripe',
  OnboardingConnectionsPageConnectionsSelectStripeTest = 'OnboardingConnectionsPageConnectionsSelectStripeTest',
  OnboardingConnectionsPageConnectionsSelectAmazonSellerCentral = 'OnboardingConnectionsPageConnectionsSelectAmazonSellerCentral',
  OnboardingConnectionsPageConnectionsSelectFreshBooks = 'OnboardingConnectionsPageConnectionsSelectFreshBooks',
  OnboardingConnectionsPageConnectionsSelectSquare = 'OnboardingConnectionsPageConnectionsSelectSquare',
  OnboardingConnectionsPageConnectionsSelectSquareSandbox = 'OnboardingConnectionsPageConnectionsSelectSquareSandbox',
  OnboardingConnectionsPageConnectionsSelectSuggestPlatformAccordion = 'OnboardingConnectionsPageConnectionsSelectSuggestPlatformAccordion',
  OnboardingConnectionsPageConnectionsSelectSuggestPlatformSelectInput = 'OnboardingConnectionsPageConnectionsSelectSuggestPlatformSelectInput',
  OnboardingConnectionsPageConnectionsSelectSuggestPlatformSubmitButton = 'OnboardingConnectionsPageConnectionsSelectSuggestPlatformSubmitButton',
  OnboardingConnectionsPageInviteTeamMemberTutorialModalOkayButton = 'OnboardingConnectionsPageInviteTeamMemberTutorialModalOkayButton',
  OnboardingLayoutLogoutButton = 'OnboardingLayoutLogoutButton',
  OnboardingApplyForFundingV3CompanyNameInput = 'OnboardingApplyForFundingV3CompanyNameInput',
  OnboardingApplyForFundingV3CompanyEinInput = 'OnboardingApplyForFundingV3CompanyEinInput',
  OnboardingApplyForFundingV3CompanyStateOfIncorporationSelectInput = 'OnboardingApplyForFundingV3CompanyStateOfIncorporationSelectInput',
  OnboardingApplyForFundingV3CompanyWebsiteInput = 'OnboardingApplyForFundingV3CompanyWebsiteInput',
  OnboardingApplyForFundingV3CompanyBusinessTypeSelectInput = 'OnboardingApplyForFundingV3CompanyBusinessTypeSelectInput',
  OnboardingApplyForFundingV3CompanyPhoneNumberInput = 'OnboardingApplyForFundingV3CompanyPhoneNumberInput',
  OnboardingApplyForFundingV3CompanyAddressAutocomplete = 'OnboardingApplyForFundingV3CompanyAddressAutocomplete',
  OnboardingApplyForFundingV3CompanyZipCodeInput = 'OnboardingApplyForFundingV3CompanyZipCodeInput',
  OnboardingApplyForFundingV3CompanyCityInput = 'OnboardingApplyForFundingV3CompanyCityInput',
  OnboardingApplyForFundingV3CompanyStateSelectInput = 'OnboardingApplyForFundingV3CompanyStateSelectInput',
  OnboardingApplyForFundingV3CompanyRevenueSelectInput = 'OnboardingApplyForFundingV3CompanyRevenueSelectInput',
  OnboardingApplyForFundingV3CompanyInformatioNextButton = 'OnboardingApplyForFundingV3CompanyInformatioNextButton',
  OnboardingApplyForFundingV3PersonalInformationFullNameInput = 'OnboardingApplyForFundingV3PersonalInformationFullNameInput',
  OnboardingApplyForFundingV3PersonalInformationCorporateEmailInput = 'OnboardingApplyForFundingV3PersonalInformationCorporateEmailInput',
  OnboardingApplyForFundingV3PersonalInformationBirthdayDateInput = 'OnboardingApplyForFundingV3PersonalInformationBirthdayDateInput',
  OnboardingApplyForFundingV3PersonalInformationPositionInCompanySelectInput = 'OnboardingApplyForFundingV3PersonalInformationPositionInCompanySelectInput',
  OnboardingApplyForFundingV3PersonalInformationPositionInCompanyInput = 'OnboardingApplyForFundingV3PersonalInformationPositionInCompanyInput',
  OnboardingApplyForFundingV3PersonalInformationPhoneNumberInput = 'OnboardingApplyForFundingV3PersonalInformationPhoneNumberInput',
  OnboardingApplyForFundingV3PersonalInformationAddressAutocomplete = 'OnboardingApplyForFundingV3PersonalInformationAddressAutocomplete',
  OnboardingApplyForFundingV3PersonalInformationZipCodeInput = 'OnboardingApplyForFundingV3PersonalInformationZipCodeInput',
  OnboardingApplyForFundingV3PersonalInformationCityInput = 'OnboardingApplyForFundingV3PersonalInformationCityInput',
  OnboardingApplyForFundingV3PersonalInformationStateSelectInput = 'OnboardingApplyForFundingV3PersonalInformationStateSelectInput',
  OnboardingApplyForFundingV3PersonalInformationIsSoleOwner = 'OnboardingApplyForFundingV3PersonalInformationIsSoleOwner',
  OnboardingApplyForFundingV3PersonalInformationNextButton = 'OnboardingApplyForFundingV3PersonalInformationNextButton',
  OnboardingApplyForFundingV4PersonalInformationFirstNameInput = 'OnboardingApplyForFundingV4PersonalInformationFirstNameInput',
  OnboardingApplyForFundingV4PersonalInformationLastNameInput = 'OnboardingApplyForFundingV4PersonalInformationLastNameInput',
  OnboardingApplyForFundingV4PersonalInformationCorporateEmailInput = 'OnboardingApplyForFundingV4PersonalInformationCorporateEmailInput',
  OnboardingApplyForFundingV4PersonalInformationBirthdayDateInput = 'OnboardingApplyForFundingV4PersonalInformationBirthdayDateInput',
  OnboardingApplyForFundingV4PersonalInformationPositionInCompanySelectInput = 'OnboardingApplyForFundingV4PersonalInformationPositionInCompanySelectInput',
  OnboardingApplyForFundingV4PersonalInformationPositionInCompanyInput = 'OnboardingApplyForFundingV4PersonalInformationPositionInCompanyInput',
  OnboardingApplyForFundingV4PersonalInformationPhoneNumberInput = 'OnboardingApplyForFundingV4PersonalInformationPhoneNumberInput',
  OnboardingApplyForFundingV4PersonalInformationAddressAutocomplete = 'OnboardingApplyForFundingV4PersonalInformationAddressAutocomplete',
  OnboardingApplyForFundingV4PersonalInformationZipCodeInput = 'OnboardingApplyForFundingV4PersonalInformationZipCodeInput',
  OnboardingApplyForFundingV4PersonalInformationCityInput = 'OnboardingApplyForFundingV4PersonalInformationCityInput',
  OnboardingApplyForFundingV4PersonalInformationStateSelectInput = 'OnboardingApplyForFundingV4PersonalInformationStateSelectInput',
  OnboardingApplyForFundingV4PersonalInformationIsSoleOwner = 'OnboardingApplyForFundingV4PersonalInformationIsSoleOwner',
  OnboardingApplyForFundingV4PersonalInformationNextButton = 'OnboardingApplyForFundingV4PersonalInformationNextButton',
  OnboardingApplyForFundingV3AddBankAccountButton = 'OnboardingApplyForFundingV3AddBankAccountButton',
  OnboardingApplyForFundingV3AddBankAccountModal = 'OnboardingApplyForFundingV3AddBankAccountModal',
  OnboardingApplyForFundingV3AddCommerceAccountButton = 'OnboardingApplyForFundingV3AddCommerceAccountButton',
  OnboardingApplyForFundingV4AddCommerceAccountButton = 'OnboardingApplyForFundingV4AddCommerceAccountButton',
  OnboardingApplyForFundingV4AddCommerceAccountModal = 'OnboardingApplyForFundingV4AddCommerceAccountModal',
  OnboardingApplyForFundingV4CommerceConnectionStepNextButton = 'OnboardingApplyForFundingV4CommerceConnectionStepNextButton',
  OnboardingApplyForFundingV3CommerceConnectionStepNextButton = 'OnboardingApplyForFundingV3CommerceConnectionStepNextButton',
  OnboardingApplyForFundingV3BankingConnectionStepNextButton = 'OnboardingApplyForFundingV3BankingConnectionStepNextButton',
  OnboardingApplyForFundingV3CompanyInformationStep = 'OnboardingApplyForFundingV3CompanyInformationStep',
  OnboardingApplyForFundingV3CompanyInformationStepEditButton = 'OnboardingApplyForFundingV3CompanyInformationStepEditButton',
  OnboardingApplyForFundingV3PersonalInformationStep = 'OnboardingApplyForFundingV3PersonalInformationStep',
  OnboardingApplyForFundingV3PersonalInformationStepEditButton = 'OnboardingApplyForFundingV3PersonalInformationStepEditButton',
  OnboardingApplyForFundingV3BankingConnectionStep = 'OnboardingApplyForFundingV3BankingConnectionStep',
  OnboardingApplyForFundingV3BankingConnectionStepEditButton = 'OnboardingApplyForFundingV3BankingConnectionStepEditButton',
  OnboardingApplyForFundingV3CommerceConnectionStep = 'OnboardingApplyForFundingV3CommerceConnectionStep',
  OnboardingApplyForFundingV3CommerceConnectionStepEditButton = 'OnboardingApplyForFundingV3CommerceConnectionStepEditButton',
  OnboardingApplyForFundingV3ReviewAndSubmitStep = 'OnboardingApplyForFundingV3ReviewAndSubmitStep',
  OnboardingApplyForFundingV3ReviewAndSubmitStepEditButton = 'OnboardingApplyForFundingV3ReviewAndSubmitStepEditButton',
  OnboardingApplyForFundingV3SubmitApplicationDialog = 'OnboardingApplyForFundingV3SubmitApplicationDialog',
  OnboardingApplyForFundingV3SubmitApplicationButton = 'OnboardingApplyForFundingV3SubmitApplicationButton',
  OnboardingCompleteApplicantInformationV3FullNameInput = 'OnboardingCompleteApplicantInformationV3FullNameInput',
  OnboardingCompleteApplicantInformationV3BirthdayDateInput = 'OnboardingCompleteApplicantInformationV3BirthdayDateInput',
  OnboardingCompleteApplicantInformationV3PositionInCompanySelectInput = 'OnboardingCompleteApplicantInformationV3PositionInCompanySelectInput',
  OnboardingCompleteApplicantInformationV3PhoneNumberInput = 'OnboardingCompleteApplicantInformationV3PhoneNumberInput',
  OnboardingCompleteApplicantInformationV3AddressAutocomplete = 'OnboardingCompleteApplicantInformationV3AddressAutocomplete',
  OnboardingCompleteApplicantInformationV3ZipCodeInput = 'OnboardingCompleteApplicantInformationV3ZipCodeInput',
  OnboardingCompleteApplicantInformationV3CityInput = 'OnboardingCompleteApplicantInformationV3CityInput',
  OnboardingCompleteApplicantInformationV3StateSelectInput = 'OnboardingCompleteApplicantInformationV3StateSelectInput',
  OnboardingCompleteApplicantInformationV3NextButton = 'OnboardingCompleteApplicantInformationV3NextButton',
  OnboardingCompleteApplicantInformationV4PersonalInformationSection = 'OnboardingCompleteApplicantInformationV4PersonalInformationSection',
  OnboardingCompleteApplicantInformationV4FirstNameInput = 'OnboardingCompleteApplicantInformationV4FirstNameInput',
  OnboardingCompleteApplicantInformationV4LastNameInput = 'OnboardingCompleteApplicantInformationV4LastNameInput',
  OnboardingCompleteApplicantInformationV4BirthdayDateInput = 'OnboardingCompleteApplicantInformationV4BirthdayDateInput',
  OnboardingCompleteApplicantInformationV4PositionInCompanySelectInput = 'OnboardingCompleteApplicantInformationV4PositionInCompanySelectInput',
  OnboardingCompleteApplicantInformationV4AddressSection = 'OnboardingCompleteApplicantInformationV4AddressSection',
  OnboardingCompleteApplicantInformationV4PhoneNumberInput = 'OnboardingCompleteApplicantInformationV4PhoneNumberInput',
  OnboardingCompleteApplicantInformationV4AddressAutocomplete = 'OnboardingCompleteApplicantInformationV4AddressAutocomplete',
  OnboardingCompleteApplicantInformationV4ZipCodeInput = 'OnboardingCompleteApplicantInformationV4ZipCodeInput',
  OnboardingCompleteApplicantInformationV4CityInput = 'OnboardingCompleteApplicantInformationV4CityInput',
  OnboardingCompleteApplicantInformationV4StateSelectInput = 'OnboardingCompleteApplicantInformationV4StateSelectInput',
  OnboardingCompleteApplicantInformationV4NextButton = 'OnboardingCompleteApplicantInformationV4NextButton',
  OnboardingApplicationSubmittedV3BankingConnectionModal = 'OnboardingApplicationSubmittedV3BankingConnectionModal',
  OnboardingApplicationSubmittedV3CommerceConnectionModal = 'OnboardingApplicationSubmittedV3CommerceConnectionModal',
  OnboardingApplicationSubmittedV3AccountingConnectionModal = 'OnboardingApplicationSubmittedV3AccountingConnectionModal',
  OnboardingApplicationSubmittedV3BankingConnectionsSection = 'OnboardingApplicationSubmittedV3BankingConnectionsSection',
  OnboardingApplicationSubmittedV3CommerceConnectionsSection = 'OnboardingApplicationSubmittedV3CommerceConnectionsSection',
  OnboardingApplicationSubmittedV3AccountingConnectionsSection = 'OnboardingApplicationSubmittedV3AccountingConnectionsSection',
  OnboardingApplicationSubmittedV3InviteTeamMemberButton = 'OnboardingApplicationSubmittedV3InviteTeamMemberButton',
  ConnectionsBankingBlockLinkConnectionModal = 'ConnectionsBankingBlockLinkConnectionModal',
  ConnectionsBankingConnectionModalContinueButton = 'ConnectionsBankingConnectionModalContinueButton',
  ConnectionsBankingConnectionModalInviteTeamMemberButton = 'ConnectionsBankingConnectionModalInviteTeamMemberButton',
  ConnectionsBankingConnectionModalCloseButton = 'ConnectionsBankingConnectionModalCloseButton',
  ConnectionsBankingBlockAddConnectionButton = 'ConnectionsBankingBlockAddConnectionButton',
  ConnectionsBankingBlockAbortConnectionButton = 'ConnectionsBankingBlockAbortConnectionButton',
  ConnectionsBankingBlockReconnectConnectionButton = 'ConnectionsBankingBlockReconnectConnectionButton',
  ConnectionsBankingBlockDeleteConnectionIconButton = 'ConnectionsBankingBlockDeleteConnectionIconButton',
  ConnectionsBankingBlockDisconnectConnectionButton = 'ConnectionsBankingBlockDisconnectConnectionButton',
  ConnectionsCommerceBlockLinkConnectionModal = 'ConnectionsCommerceBlockLinkConnectionModal',
  ConnectionsCommerceBlockAddConnectionButton = 'ConnectionsCommerceBlockAddConnectionButton',
  ConnectionsCommerceBlockAbortConnectionButton = 'ConnectionsCommerceBlockAbortConnectionButton',
  ConnectionsCommerceBlockReconnectConnectionButton = 'ConnectionsCommerceBlockReconnectConnectionButton',
  ConnectionsCommerceBlockDeleteConnectionIconButton = 'ConnectionsCommerceBlockDeleteConnectionIconButton',
  ConnectionsCommerceBlockDisconnectConnectionButton = 'ConnectionsCommerceBlockDisconnectConnectionButton',
  ConnectionsAccountingBlockLinkConnectionModal = 'ConnectionsAccountingBlockLinkConnectionModal',
  ConnectionsAccountingBlockAddConnectionButton = 'ConnectionsAccountingBlockAddConnectionButton',
  ConnectionsAccountingBlockAbortConnectionButton = 'ConnectionsAccountingBlockAbortConnectionButton',
  ConnectionsAccountingBlockReconnectConnectionButton = 'ConnectionsAccountingBlockReconnectConnectionButton',
  ConnectionsAccountingBlockDeleteConnectionIconButton = 'ConnectionsAccountingBlockDeleteConnectionIconButton',
  ConnectionsAccountingBlockDisconnectConnectionButton = 'ConnectionsAccountingBlockDisconnectConnectionButton',
  ActionButtonContainerAbortConnectionButton = 'ActionButtonContainerAbortConnectionButton',
  ActionButtonContainerReconnectConnectionButton = 'ActionButtonContainerReconnectConnectionButton',
  ActionButtonContainerDeleteConnectionIconButton = 'ActionButtonContainerDeleteConnectionIconButton',
  ActionButtonContainerDisconnectConnectionButton = 'ActionButtonContainerDisconnectConnectionButton',
}
