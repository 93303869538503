import { FC, lazy, Suspense } from 'react';
import { routes } from '@rbf/routes/config';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { OnboardingLayout } from '@rbf/components/OnboardingLayout';
import { OnboardingLayout as OnboardingLayoutV2 } from '@rbf/components/OnboardingLayoutV2';
import { LinearRouteInitializedOnceContextProvider } from './contexts/LinearRouteInitializedOnceContext/LinearRouteInitializedOnceContextProvider';
import { useLinearRouteInitializedOnceContext } from './contexts/LinearRouteInitializedOnceContext/hooks/useLinearRouteInitializedOnceContext';
import { useLinearRouteInitialization } from './contexts/LinearRouteInitializedOnceContext/hooks/useLinearRouteInitialization';
import { isDevFeatureEnabled } from '@rbf/components/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@rbf/components/DevFeatureToggleContainer/enums';
import { OfferRoutes } from './steps/OffersRoutes';

const DocumentsPage = lazy(() => import('../DocumentsPage'));

const FundingRejectedPage = lazy(() => import('./steps/FundingRejectedPage'));
const OfferExpiredPage = lazy(() => import('./steps/OfferExpiredPage'));
const GetFundedPage = lazy(() => import('./steps/GetFundedPage'));
const CheckConnectionsPage = lazy(() => import('./steps/CheckConnectionsPage'));

const ApplicationSubmittedPage = lazy(() => import('./steps/ApplicationSubmittedPage'));
const ApplicationSubmittedConnectionsPage = lazy(() => import('./steps/ApplicationSubmittedConnectionsPage'));
const RequestedDataPage = lazy(() => import('./steps/RequestedDataPage'));
const ConditionalOfferConnectionsPage = lazy(() => import('./steps/ConditionalOfferConnectionsPage'));

const CompleteInvitedApplicantInformation = lazy(() => import('@rbf/Pages/CompleteInvitedApplicantInformationV3'));
const CompleteInvitedApplicantInformationV4 = lazy(() => import('@rbf/Pages/CompleteInvitedApplicantInformationV4'));

const ApplyForFundingPage = lazy(() => import('./steps/ApplyForFundingPageV3'));

const Layout = isDevFeatureEnabled(EDevFeature.ONBOARDING_LAYOUT_RESTYLE) ? OnboardingLayoutV2 : OnboardingLayout;

const RouteContainer: FC = () => (
  <Layout isLogoutButtonShown>
    <Suspense
      fallback={
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      }
    >
      <Outlet />
    </Suspense>
  </Layout>
);

const LinearRouteInitializator: FC = () => {
  const { isInitialized } = useLinearRouteInitializedOnceContext();
  useLinearRouteInitialization();

  return isInitialized ? (
    <Outlet />
  ) : (
    <Stack flexGrow={1} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  );
};

const getPath = (path: string): string => path.replace(routes.onboarding.path, '');

const OnboardingRoutes: FC = () => {
  return (
    <Routes>
      <Route
        element={
          <LinearRouteInitializedOnceContextProvider>
            <Outlet />
          </LinearRouteInitializedOnceContextProvider>
        }
      >
        <Route element={<RouteContainer />}>
          <Route element={<LinearRouteInitializator />}>
            <Route path={getPath(routes.onboarding.applyForFunding.path)} element={<ApplyForFundingPage />} />

            {/* Application submitted. No way back into the linear flow  */}
            <Route path={getPath(routes.onboarding.applicationSubmitted.path)} element={<ApplicationSubmittedPage />} />
            <Route
              path={getPath(routes.onboarding.applicationSubmitted.connections.path)}
              element={<ApplicationSubmittedConnectionsPage />}
            />
            <Route
              path={getPath(routes.onboarding.offers.path) + '/*'}
              element={
                isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) ? (
                  <OfferRoutes />
                ) : (
                  <Navigate to={routes.onboarding.path} />
                )
              }
            />
            <Route path={getPath(routes.onboarding.conditionalOffer.path)} element={<RequestedDataPage />} />
            <Route
              path={getPath(routes.onboarding.conditionalOffer.connections.path)}
              element={<ConditionalOfferConnectionsPage />}
            />

            <Route path={getPath(routes.onboarding.fundingRejected.path)} element={<FundingRejectedPage />} />
            <Route path={getPath(routes.onboarding.offerExpired.path)} element={<OfferExpiredPage />} />
            <Route path={getPath(routes.onboarding.getFunded.path)} element={<GetFundedPage />} />
            <Route path={getPath(routes.onboarding.getFunded.connections.path)} element={<CheckConnectionsPage />} />

            <Route
              path={getPath(routes.onboarding.completeApplicantInformation.path)}
              element={
                isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) ? (
                  <CompleteInvitedApplicantInformationV4 />
                ) : (
                  <CompleteInvitedApplicantInformation />
                )
              }
            />
            {/* @TODO: no access this page from dashboard? */}
            <Route path={getPath(routes.onboarding.documents.path)} element={<DocumentsPage />} />
          </Route>
          <Route path="*" element={<LinearRouteInitializator />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default OnboardingRoutes;
