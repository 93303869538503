import { Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useAuth } from '@stenngroup/auth0-sdk';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { createRef, FC, ReactNode, useEffect, useState } from 'react';
import { ERBFTestId } from '@rbf/testIds';
import artWork from './artwork.jpg';
import { Button } from '@stenngroup/ui-kit';
import { StennLogo } from '@rbf/components/StennLogo';
import { StennLogo as StennLogoColored } from '@stenngroup/ui-kit';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const TitleByState: Record<number, ReactNode> = {
  [1]: (
    <>
      Funding to match your growth.
      <br />
      And boost it.
    </>
  ),
  [2]: <>Working capital that works as hard as you.</>,
  [3]: (
    <>
      The banks back us.
      <br />
      We back you.
    </>
  ),
  [4]: <>&quot;100% the best way to help your brand grow&quot;</>,
  [5]: <>Finance that moves as fast as your business</>,
  [6]: (
    <>
      Fast funding.
      <br />
      No strings attached.
    </>
  ),
};

const DescriptionByState: Record<number, ReactNode> = {
  [1]: <>Stenn has funded over $18B to help small businesses thrive and grow. Now, it&apos;s your turn.</>,
  [2]: <>Get funding to supercharge your business – in 24 hours or less.</>,
  [3]: <>From Citi to HSBC, we&apos;re backed by the biggest banks in the world.</>,
  [4]: (
    <>
      &quot;I&apos;ve tried other growth capital partners and by far this has been the easiest and best to work
      with.&quot; - Love and Pebble
    </>
  ),
  [5]: <>Get fast access to capital with no limits. Then get on with running your business.</>,
  [6]: <>Easy capital to put you back in control of your business growth.</>,
};

export const PurpleBlock: FC<{ isLogoutButtonShown: boolean }> = (props) => {
  const { isLogoutButtonShown } = props;
  const { logout } = useAuth();
  const [activeText, setActiveText] = useState(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const showOnlyLogo = !isLogoutButtonShown && isSmallScreen;

  useEffect(() => {
    const interval = window.setInterval(() => {
      setActiveText((prev) => (prev === 6 ? 1 : prev + 1));
    }, 10000);

    return () => window.clearInterval(interval);
  }, []);

  return showOnlyLogo ? (
    <Stack alignItems="center" padding={1}>
      <StennLogoColored />
    </Stack>
  ) : (
    <Stack
      paddingY={1}
      sx={(t) => ({
        backgroundImage: `url(${artWork})`,
        backgroundSize: 'cover',
        backgroundPositionY: '80%',
        [t.breakpoints.up('sm')]: {
          paddingY: 2,
        },
        [t.breakpoints.up('lg')]: {
          flexGrow: 1,
          padding: 0,
        },
      })}
    >
      <Stack
        direction="row-reverse"
        alignItems="center"
        justifyContent="space-between"
        flexGrow={1}
        paddingX={2}
        gap={4}
        sx={(t) => ({
          [t.breakpoints.up('sm')]: {
            paddingX: 5,
          },
          [t.breakpoints.up('lg')]: {
            padding: 0,
            alignItems: 'flex-start',
            flexDirection: 'column',
          },
        })}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          sx={(t) => ({
            [t.breakpoints.up('lg')]: {
              paddingTop: 15,
              paddingX: 16,
              alignItems: 'flex-start',
              flexGrow: 1,
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            },
          })}
        >
          <Box
            width={43}
            height={30}
            sx={(t) => ({
              [t.breakpoints.up('sm')]: {
                height: 44,
                width: 63,
              },
            })}
          >
            <StennLogo />
          </Box>
        </Stack>

        <Stack
          display="none"
          alignSelf="center"
          flexGrow={1}
          gap={1.15}
          sx={(t) => ({
            [t.breakpoints.up('lg')]: {
              display: 'flex',
              paddingX: 16,
            },
          })}
          width="100%"
          height={180}
          position="relative"
        >
          <TransitionGroup component={null}>
            {Array.from({ length: Object.keys(TitleByState).length }).map((_, index) => {
              if (index + 1 !== activeText) return null;
              const ref = createRef<HTMLDivElement>();
              return (
                <CSSTransition
                  key={index + 1}
                  classNames={{
                    enter: 'slideEnter',
                    enterActive: 'slideEnterActive',
                    exit: 'slideExit',
                    exitActive: 'slideExitActive',
                  }}
                  nodeRef={ref}
                  addEndListener={(done) => ref.current!.addEventListener('transitionend', done, false)}
                >
                  <Box
                    ref={ref}
                    position="absolute"
                    width="60%"
                    sx={{
                      '&.slideEnter': {
                        transform: 'translateX(200%)',
                      },
                      '&.slideEnterActive': {
                        transform: 'translateX(0)',
                        transition: 'transform 850ms ease-in-out',
                      },
                      '&.slideExit': {
                        transform: 'translateX(0)',
                      },
                      '&.slideExitActive': {
                        transform: 'translateX(-200%)',
                        transition: 'transform 850ms ease-in-out',
                      },
                    }}
                  >
                    <Stack flexGrow={1} gap={1.15}>
                      <Typography color={(t) => t.palette.common.white} variant="caption">
                        REVENUE BASED FINANCE
                      </Typography>
                      <Typography color={(t) => t.palette.common.white} variant="h4">
                        {TitleByState[activeText]}
                      </Typography>
                      <Typography color={(t) => t.palette.common.white} variant="body1">
                        {DescriptionByState[activeText]}
                      </Typography>
                    </Stack>
                  </Box>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </Stack>
        <Box
          sx={(t) => ({
            [t.breakpoints.up('lg')]: {
              paddingX: 16,
              paddingBottom: 15,
            },
          })}
        >
          {isLogoutButtonShown && (
            <Button
              color="primary"
              variant="tertiary"
              size="small"
              onClick={() => logout()}
              data-testid={ERBFTestId.OnboardingLayoutLogoutButton}
            >
              <LogoutIcon sx={{ mr: 1 }} />
              Log out
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
