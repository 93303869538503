/** all RBF local storage keys or key prefixes */
export const enum ELocalStorageKey {
  ThemeModeSetting = 'theme-mode-setting',
  SideNavCollapsed = 'side-nav-collapsed',
  AuthData = 'auth0-data',
  ReferralURLSearch = 'referral-url-search',
  OptimisticSigningPsExpires = 'optimistic-signing-ps-expires',
  OptimisticSigningBySignatory = 'optimistic-signing-by-signatory',
  OptimisticAchAuthorizationGetFunded = 'optimistic-ach-authorization-get-funded',
  OptimisticAchAuthorizationDashboard = 'optimistic-ach-authorization-dashboard',
  OptimisticAchAuthorizedFromDashboard = 'optimistic-ach-authorized-from-dashboard',
  InvitingTeamMembersDialogClosed = 'inviting-team-members-dialog-closed',
  DevFeatures = 'dev-features',
}
