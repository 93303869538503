import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { EQueryKey } from '@rbf/api/constants';
import { useAuth } from '@stenngroup/auth0-sdk';
import { UseQueryResult } from 'react-query/types/react/types';
import { useGraphQLClient } from '@rbf/core/graphql/hooks/useGraphQLClient';
import { EGraphQLService } from '@rbf/core/graphql/enums';
import { profileQueryDocument } from '@rbf/core/graphql-documents/profileQueryDocument.rbf';
import { ProfileQuery } from '@rbf/__generated__/graphql/rbf/graphql';

export const useGetProfile = (): UseQueryResult<ProfileQuery['profile']> => {
  const { isAuthenticated } = useAuth();

  const rbfGraphQLClient = useGraphQLClient(EGraphQLService.rbf);

  return useQuery(
    [EQueryKey.GetProfile],
    async ({ signal }) => rbfGraphQLClient.request({ document: profileQueryDocument, signal }),
    {
      select: (res) => res.profile,
      enabled: isAuthenticated,
      refetchOnMount: true,
    }
  );
};

export const usePrefetchGetProfile = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();

  const rbfGraphQLClient = useGraphQLClient(EGraphQLService.rbf);

  const prefetchGetProfile = useCallback(async () => {
    return queryClient.prefetchQuery({
      queryKey: EQueryKey.GetProfile,
      queryFn: async ({ signal }) => rbfGraphQLClient.request({ document: profileQueryDocument, signal }),
    });
  }, [queryClient, rbfGraphQLClient]);

  return prefetchGetProfile;
};
