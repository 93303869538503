import { FC, useEffect, useState } from 'react';
import { useGetProfile } from '@rbf/api/profle/useGetProfile';
import { useAuth } from '@stenngroup/auth0-sdk';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@stenngroup/ui-kit';

const TIME_BEFORE_LOGOUT_AFTER_DIALOG_SHOWN = 1 * 60 * 1000;
const TIME_BEFORE_DIALOG_SHOWN = 4 * 60 * 1000;
const TOKEN_REFRESH_DIFF = 60 * 1000;

const getSecondsLeft = (targetTime: number, diff: number): number => {
  const currentDate: Date = new Date();
  currentDate.setTime(currentDate.getTime() - diff);
  const targetDate: Date = new Date(targetTime);
  const differenceInMilliseconds: number = targetDate.getTime() - currentDate.getTime();
  return Math.floor(differenceInMilliseconds / 1000);
};

/**
 * @description
 * * The last user activity is considered to be the most recent occurrence of one of the following events: clicks, key presses, scrolling, or tab switching.
 *
 * * The access token is refreshed every minute as long as the following conditions are met:
 *
 * * The user is logged in.
 * * * The user either doesn't have a wallet, or they have a wallet and are active (an event has occurred within the last 4 minutes).
 * * * If no event occurs within 4 minutes, the user is considered inactive, and a dialog is displayed with "Log out" and "Stay" buttons. The "Stay" button includes a countdown timer in seconds.
 *
 * * From the moment the dialog is shown, the user has one minute to confirm that they are still present. During this time, events from the first point no longer affect the outcome (e.g., moving the mouse on the screen will not dismiss the dialog).
 *
 * * The user needs to click either the "Stay" button or anywhere outside the dialog for it to disappear and for the user to remain logged in. At this moment, the token will be immediately refreshed (and the token refresh interval from the second point will resume).
 */
export const InactivityDialogContainer: FC = () => {
  const { getAccessTokenSilently, logout, isAuthenticated } = useAuth();
  const { data: profile } = useGetProfile();

  const [lastActiveTime, setLastActiveTime] = useState(Date.now());
  const [isActive, setIsActive] = useState(true);
  const [dialogShownAt, setDialogShownAt] = useState<number | null>(null);

  useEffect(() => {
    setIsActive(Date.now() - lastActiveTime <= TIME_BEFORE_DIALOG_SHOWN);
  }, [lastActiveTime]);

  const hasWallet =
    // @ts-expect-error will be there
    profile?.hasWallet ?? true;

  // refresh access token silently by interval if there's no wallet
  useEffect(() => {
    if (!isAuthenticated) return;
    if (hasWallet === true && !isActive) return;
    let lastRefresh = Date.now();
    const interval = window.setInterval(() => {
      if (Date.now() - lastRefresh >= TOKEN_REFRESH_DIFF) {
        getAccessTokenSilently({ force: true });
        lastRefresh = Date.now();
      }
    }, 1000);

    return (): void => window.clearInterval(interval);
  }, [isAuthenticated, hasWallet, isActive, getAccessTokenSilently]);

  const [secondsLeft, setSecondsLeft] = useState<null | number>(null);

  useEffect(() => {
    if (!isAuthenticated) return;

    const interval = window.setInterval(() => {
      if (dialogShownAt !== null) return;
      if (Date.now() - lastActiveTime >= TIME_BEFORE_DIALOG_SHOWN) {
        const shownAt = Date.now();
        setDialogShownAt(shownAt);
        setSecondsLeft(getSecondsLeft(shownAt, TIME_BEFORE_LOGOUT_AFTER_DIALOG_SHOWN));
        window.clearInterval(interval);
      }
    }, 1000);
    return () => window.clearInterval(interval);
  }, [lastActiveTime, dialogShownAt, isAuthenticated]);

  useEffect(() => {
    if (dialogShownAt === null) return;

    const interval = window.setInterval(() => {
      setSecondsLeft(getSecondsLeft(dialogShownAt, TIME_BEFORE_LOGOUT_AFTER_DIALOG_SHOWN));
    }, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }, [dialogShownAt, logout]);

  useEffect(() => {
    if (dialogShownAt === null) return;

    const interval = window.setInterval(() => {
      if (Date.now() - dialogShownAt >= TIME_BEFORE_LOGOUT_AFTER_DIALOG_SHOWN) {
        logout();
        setDialogShownAt(null);
        setSecondsLeft(null);
      }
    }, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }, [dialogShownAt, logout]);

  useEffect(() => {
    const handleEvent = (): void => {
      const date = new Date();
      // to not implement throttle just set milliseconds to 0 and dates will be the same
      date.setMilliseconds(0);
      setLastActiveTime(date.getTime());
    };

    const events = ['mousedown', 'mousemove', 'keypress', 'click', 'touchstart', 'scroll', 'visibilitychange'];
    events.forEach((name) => {
      document.addEventListener(name, handleEvent, true);
    });

    return () => {
      events.forEach((name) => {
        document.removeEventListener(name, handleEvent, true);
      });
    };
  }, []);

  const handleStayLoggedInClick = async (): Promise<void> => {
    setLastActiveTime(Date.now());
    setDialogShownAt(null);
    setSecondsLeft(null);
    await getAccessTokenSilently({ force: true });
  };

  return (
    <Dialog open={dialogShownAt !== null} onClose={handleStayLoggedInClick} fullWidth maxWidth="sm" isDrawerLike>
      <DialogTitle>Still there?</DialogTitle>
      <DialogContent>
        Your session is about to expire due to inactivity. To ensure the security of your financial information, we will
        log you out in <Typography.TextMd.Bold>60 seconds</Typography.TextMd.Bold> if no action is taken.
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" size="medium" color="primary" onClick={() => logout()}>
          Log out
        </Button>
        <Button color="primary" variant="primary" onClick={handleStayLoggedInClick}>
          Stay logged in ({secondsLeft != null && secondsLeft > 0 ? secondsLeft : 0})
        </Button>
      </DialogActions>
    </Dialog>
  );
};
