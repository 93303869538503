import { ELocalStorageKey } from '@rbf/storage-keys';

import UAParser from 'ua-parser-js';
import { COOKIE_KEYS, getCookiesTrackingData } from '@rbf/context/AnalyticsProvider/cookies/cookiesRoutines';
import { IProcessTrackingDataReturn } from './types';

import { getTrackingSourceType } from '@rbf/context/AnalyticsProvider/utils';
import { CookiesStorageService } from '../cookies/CookiesStorageService';

export const UA_DETAILS = new UAParser().getResult();

export const processTrackingData = (): IProcessTrackingDataReturn => {
  const cookiesTrackingData = getCookiesTrackingData();
  const searchFromStorage = localStorage.getItem(ELocalStorageKey.ReferralURLSearch);

  const urlSearchParamsFromStorage = new URLSearchParams(searchFromStorage as string);
  const firstLandingSearchParams = new URLSearchParams(
    new URL(cookiesTrackingData[COOKIE_KEYS.firstLandingUrl]).search
  );

  const firstLandingReferrer = cookiesTrackingData[COOKIE_KEYS.firstLandingReferrer] as string | undefined;
  let internal_referrer_url: string | null = null;
  let external_referrer_url: string | null = null;
  if (firstLandingReferrer) {
    if (firstLandingReferrer.includes('stenn.com')) {
      internal_referrer_url = firstLandingReferrer;
    } else {
      external_referrer_url = firstLandingReferrer;
    }
  }

  return {
    landingUrl: cookiesTrackingData[COOKIE_KEYS.firstLandingUrl],
    referer: cookiesTrackingData.g, // backend expects it to be with one r
    webAnalytics: {
      firstLandingPage:
        typeof cookiesTrackingData[COOKIE_KEYS.firstLandingUrl] === 'string'
          ? cookiesTrackingData[COOKIE_KEYS.firstLandingUrl].split('?')[0]
          : '',
      internal_referrer_url,
      external_referrer_url,
      buttonName: urlSearchParamsFromStorage.get('buttonName') || null,
      source:
        firstLandingSearchParams.get('utm_source') || CookiesStorageService.getValue('stenn_app_utm_source') || null,
      campaign:
        firstLandingSearchParams.get('utm_campaign') ||
        CookiesStorageService.getValue('stenn_app_utm_campaign') ||
        null,
      medium:
        firstLandingSearchParams.get('utm_medium') || CookiesStorageService.getValue('stenn_app_utm_medium') || null,
      campaignId:
        firstLandingSearchParams.get('utm_campaign_id') ||
        CookiesStorageService.getValue('stenn_app_utm_campaign_id') ||
        null,
      term: firstLandingSearchParams.get('utm_term') || CookiesStorageService.getValue('stenn_app_utm_term') || null,
      content:
        firstLandingSearchParams.get('utm_content') || CookiesStorageService.getValue('stenn_app_utm_content') || null,
      bannerShownAt: firstLandingSearchParams.get('pmt') || null,
      gclid: firstLandingSearchParams.get('gclid') || CookiesStorageService.getValue('stenn_app_gclid') || null,
      creativeid:
        firstLandingSearchParams.get('creative_id') || CookiesStorageService.getValue('stenn_app_creative_id') || null,
      sourceType: getTrackingSourceType(
        firstLandingSearchParams,
        cookiesTrackingData[COOKIE_KEYS.firstLandingReferrer]
      ),
    },
    techData: {
      deviceType: UA_DETAILS.device.type || 'desktop',
      browser: `${UA_DETAILS.browser.name} ${UA_DETAILS.browser.version}`,
      preferredBrowserLanguage: navigator.language,
      operatingSystem: `${UA_DETAILS.os.name} ${UA_DETAILS.os.version}`,
      // @TODO can we delete it?
      hasAdblock: false,
      // https://www.w3.org/TR/tracking-dnt/#dom-navigator-donottrack
      doNotTrack: Number(typeof navigator.doNotTrack === 'string' && navigator.doNotTrack.startsWith('1')).toString(),
    },
  };
};
