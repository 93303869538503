import { Refresh as RefreshIcon, WarningRounded as WarningIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { Button, StennLogo } from '@stenngroup/ui-kit';
import { FC } from 'react';

export const CriticalErrorLayout: FC = () => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      gap={1.25}
      height="100%"
      padding={10}
      bgcolor={(t) => t.palette.common.white}
      sx={(t) => ({
        padding: 3,
        [t.breakpoints.up('md')]: {
          padding: 7.5,
        },
        [t.breakpoints.up('lg')]: {
          padding: 10,
        },
      })}
    >
      <Box>
        <StennLogo />
      </Box>
      <Stack gap={3}>
        <Stack direction="row" gap={1} alignItems="center">
          <WarningIcon fontSize="large" sx={(t) => ({ color: t.palette.warning.main })} />
          <Typography variant="h4" color={(t) => t.palette.text.primary}>
            Something went wrong…
          </Typography>
        </Stack>
        <Typography variant="body1" color={(t) => t.palette.text.secondary}>
          Sorry about this! Please reload the page.
        </Typography>
        <Box>
          <Button
            size="large"
            variant="contained"
            color="primary"
            endIcon={<RefreshIcon />}
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </Box>
      </Stack>
      <div />
    </Stack>
  );
};
