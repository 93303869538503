import { graphql } from '@rbf/__generated__/graphql/rbf';

export const currentApplicationQueryDocument = graphql(/* GraphQL */ `
  query currentApplication {
    currentApplication {
      id
      state
      stateV2
      closeReason
      eligibility
      desiredLimit
      revenue
      offer {
        state
        closeReason
        offerTerms {
          amount
          feesRate
          paymentCadence
          repaymentRate
          tenor
          daysValidAfterIssue
          maxPaymentAmount
          expiresAt
        }
        requestedConnections {
          id
          platform {
            id
            name
            type
            key
          }
          connection {
            id
            state
            identity
          }
          comment
        }
      }
      achConnection {
        id
        state
        verificationUrl
        clientSecret
        accountNumber
      }
      initialTransactionModel {
        canBeEdited
        details {
          amount
          minLimit
          maxLimit
        }
      }
      legalDocumentsPackageModel {
        isFullySigned
        signatures {
          fullName
          email
          isSigned
        }
      }
      company {
        id
        name
        ein
        stateOfIncorporation
        website
        businessType
        legalEntityTypeModel {
          id
          name
        }
        businessPhone {
          number
          countryCode
        }
        businessAddress {
          placeId
          countryId
          locality
          addressLine
          postalCode
          stateId
        }
      }
    }
  }
`);
