(function (gtmId) {
    if (!gtmId) {
        return;
    }

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
    });

    const firstScript = document.getElementsByTagName('script')[0];
    const gtmScript = document.createElement('script');

    gtmScript.async = true;
    gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId;

    firstScript.parentNode.insertBefore(gtmScript, firstScript);
})(window._STENN_.GTM_ID);
