import { FC, ReactElement } from 'react';

import { Stack } from '@mui/material';
import { ERBFTestId } from '@rbf/testIds';
import { SideBlock } from './SideBlock';

const MD_WIDTH = 380;
const LG_WIDTH = 540;

export const OnboardingLayout: FC<{ children: ReactElement; isLogoutButtonShown: boolean }> = (props) => {
  const { children, isLogoutButtonShown } = props;

  return (
    <Stack flexGrow={1} height="100%">
      <Stack
        direction="column"
        flexGrow={1}
        sx={(t) => ({
          [t.breakpoints.up('md')]: {
            height: '100%',
            flexDirection: 'row',
          },
        })}
      >
        {/* Placeholder with the same widths to push content for screens above lg */}
        <Stack
          flexShrink={0}
          sx={(t) => ({
            [t.breakpoints.up('md')]: {
              width: MD_WIDTH,
            },
            [t.breakpoints.up('lg')]: {
              width: LG_WIDTH,
            },
          })}
        />
        <Stack
          flexShrink={0}
          sx={(t) => ({
            width: '100%',
            [t.breakpoints.up('md')]: {
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              width: MD_WIDTH,
            },
            [t.breakpoints.up('lg')]: { width: LG_WIDTH },
          })}
        >
          <SideBlock isLogoutButtonShown={isLogoutButtonShown} />
        </Stack>

        <Stack flexGrow={1} data-testid={ERBFTestId.OnboardingLayoutMainContent}>
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};
