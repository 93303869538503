export const routes = {
  /**
   * Redirect to needed page — if authenticated to /onboarding/.. or to /dashboard/..
   * If not authenticated — to login page
   */
  root: {
    path: '/',
  },
  invitation: {
    path: '/invitation',
    /** Public route, can be accessed only when not authenticated */
    accept: {
      path: '/invitation/accept',
    },
    completeApplicantInformation: {
      path: '/invitation/complete-applicant-information',
    },
  },
  /** Routes that can be accessed only when unauthenticated */
  auth: {
    path: '/auth',
    login: {
      path: '/auth/login',
    },
    forgotPassword: {
      path: '/auth/forgot-password',
      changePassword: {
        path: '/auth/forgot-password/change-password',
      },
      emailConfirmation: {
        path: '/auth/forgot-password/email-confirmation',
      },
      passwordSetted: {
        path: '/auth/forgot-password/password-setted',
      },
    },
    signUp: {
      path: '/auth/sign-up',
    },
    emailConfirmation: {
      path: '/auth/email-confirmation',
    },
    phoneConfirmation: {
      path: '/auth/phone-confirmation',
    },
  },
  /** Private routes, can be accessed only when authenticated */
  dashboard: {
    path: '/dashboard',
    transactions: {
      path: '/dashboard/transactions',
    },
    settings: {
      path: '/dashboard/settings',
      account: {
        path: '/dashboard/settings/account',
      },
    },
    connections: {
      path: '/dashboard/connections',
    },
    documents: {
      path: '/dashboard/documents',
    },
    teamMembers: {
      path: '/dashboard/team-members',
    },
    repaymentRequestModification: {
      path: '/dashboard/repayment-request-modification',
    },
    wallet: {
      path: '/dashboard/wallet',
    },
  },
  /** Private routes, can be accessed only when authenticated */
  onboarding: {
    path: '/onboarding',
    companyInformation: {
      path: '/onboarding/company-information',
      basicInformation: {
        path: '/onboarding/company-information/basic-information',
      },
      businessAddress: {
        path: '/onboarding/company-information/business-address',
      },
      mailingAddress: {
        path: '/onboarding/company-information/mailing-address',
      },
    },
    desiredLimit: {
      path: '/onboarding/desired-limit',
    },
    applicantInformation: {
      path: '/onboarding/applicant-information',
      basicInformation: {
        path: '/onboarding/applicant-information/basic-information',
      },
      contactInformation: {
        path: '/onboarding/applicant-information/contact-information',
      },
    },
    connections: {
      path: '/onboarding/connections',
    },
    applyForFunding: {
      path: '/onboarding/apply-for-funding',
    },
    applicationSubmitted: {
      path: '/onboarding/application-submitted',
      connections: {
        path: '/onboarding/application-submitted/connections',
      },
    },
    fundingRejected: {
      path: '/onboarding/funding-rejected',
    },
    offerExpired: {
      path: '/onboarding/offer-expired',
    },
    getFunded: {
      path: '/onboarding/get-funded',
      connections: {
        path: '/onboarding/get-funded/connections',
      },
    },
    completeApplicantInformation: {
      path: '/onboarding/complete-applicant-information',
    },
    documents: {
      path: '/onboarding/documents',
    },
    settings: {
      path: '/onboarding/settings',
      account: {
        path: '/onboarding/settings/account',
      },
    },
    conditionalOffer: {
      path: '/onboarding/conditional-offer',
      connections: {
        path: '/onboarding/conditional-offer/connections',
      },
    },
    offers: {
      path: '/onboarding/offers',
    },
  },
  /** Private routes, can be accessed only when authenticated */
  achInfo: {
    path: '/ach-info',
  },
  /** Public route, can be accessed when authenticated and unauthenticated */
  termsOfUse: {
    path: '/terms-of-use',
  },
  codatReturn: {
    // @TODO ask bankend to accept dynamic redirect route or change route to not /onboarding
    path: '/onboarding/codat-return',
  },
  docuSignReturn: {
    path: '/docu-sign-return',
  },
};
