import { FC, ReactNode, useMemo, useState } from 'react';
import { LinearRouteInitializedOnceContext } from '../context';
import { ILinearRouteInitializedOnceContext } from '../types';

export const LinearRouteInitializedOnceContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const [isInitialized, setInitialized] = useState(false);

  const value: ILinearRouteInitializedOnceContext = useMemo(
    () => ({
      isInitialized,
      setInitialized: () => setInitialized(true),
    }),
    [isInitialized]
  );

  return (
    <LinearRouteInitializedOnceContext.Provider value={value}>{children}</LinearRouteInitializedOnceContext.Provider>
  );
};
