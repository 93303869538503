import { FC } from 'react';
import { AcceptPolicies } from '@stenngroup/ui-kit';
import { useMutation, useQuery } from 'react-query';
import { useGraphQLClient } from '@rbf/core/graphql/hooks/useGraphQLClient';
import { EGraphQLService } from '@rbf/core/graphql/enums';
import { profileQueryDocument } from '@rbf/core/graphql-documents/profileQueryDocument.rbf';
import { createGetQueryKey } from '@rbf/core/graphql/helpers/createGetQueryKey';
import { useAuth } from '@stenngroup/auth0-sdk';
import { acceptLegalPoliciesMutationDocument } from './graphql-documents/acceptLegalPoliciesMutationDocument.rbf';
import { useGetProfile } from '@rbf/api/profle/useGetProfile';

export const AcceptPoliciesContainer: FC = () => {
  const { logout, isAuthenticated } = useAuth();

  const rbfGraphQLClient = useGraphQLClient(EGraphQLService.rbf);

  const { data: restProfile } = useGetProfile();
  const {
    data: profile,
    refetch: refetchProfile,
    isRefetching: isProfileRefetching,
  } = useQuery(
    [createGetQueryKey(EGraphQLService.rbf)(profileQueryDocument)],
    ({ signal }) => rbfGraphQLClient.request({ document: profileQueryDocument, signal }),
    {
      enabled: isAuthenticated && !!restProfile,
      select: (res) => res.profile,
    }
  );

  const acceptPoliciesMutation = useMutation(() => rbfGraphQLClient.request(acceptLegalPoliciesMutationDocument), {
    onSuccess: () => refetchProfile(),
  });

  if (!isAuthenticated || !profile) return null;
  if (profile.hasAcceptedLegalPolicies) return null;

  return (
    <AcceptPolicies
      locale="en"
      onAgreeClick={() => acceptPoliciesMutation.mutate()}
      onLogOutClick={() => logout()}
      isAgreeButtonLoading={acceptPoliciesMutation.isLoading || isProfileRefetching}
    />
  );
};
