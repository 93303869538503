import { FetchError } from '@rbf/core/api/lib/FetchError';
import { isGraphQLEndpointError } from '@rbf/core/graphql/helpers/isGraphQLEndpointError';
import { captureException } from '@sentry/react';
import { useToast } from '@stenngroup/ui-kit';
import isNetworkError from 'is-network-error';
import { FC, ReactNode, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createFingerprint } from './helpers/createFingerprint';

const genericMessage = 'An error occurred while the system was running.';

export const QueryClientProviderWrapper: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const toast = useToast();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            // @TODO make it false
            refetchOnReconnect: true,
            // @TODO make it true
            refetchOnMount: false,
            keepPreviousData: false,
            onError: (err): void => {
              if (err instanceof FetchError) {
                captureException(err.fetchError);
              } else if (isGraphQLEndpointError(err)) {
                captureException(err, { fingerprint: [createFingerprint(err)] });
              }
              toast.showError(genericMessage);
            },
            retry: (failureCount, error): boolean => {
              // retry only in case if it's network error — for example, the user entered the subway metro tunnel and was lost
              // CORS error, invalid URL and backend responses are not considered network errors
              if (isNetworkError(error) || (error instanceof FetchError && isNetworkError(error.fetchError))) {
                return failureCount < 3;
              }
              return false;
            },
          },
          mutations: {
            onError: (err): void => {
              if (err instanceof FetchError) {
                captureException(err.fetchError);
              } else if (isGraphQLEndpointError(err)) {
                captureException(err, { fingerprint: [createFingerprint(err)] });
              }
              toast.showError(genericMessage);
            },
          },
        },
      }),
    [toast]
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
