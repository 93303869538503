import { FC, memo, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';

import { ISignalrContext, ISignalrResponse, TSignalrMessageType, TSubscriptionCallback } from './types';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { SignalrContext } from './context';
import { useAuth } from '@stenngroup/auth0-sdk';
import { captureException } from '@sentry/react';
import { subscribeFactory } from './helpers/subscribeFactory';
import { unsubscribeFactory } from './helpers/unscribeFactory';

class AccessTokenError extends Error {}

const SignalrProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  const connectionHubInstance = useRef<HubConnection | null>(null);
  const subscriptions = useRef<Map<TSignalrMessageType, Map<string, TSubscriptionCallback>>>(new Map());

  const subscribe = useCallback(
    (type: TSignalrMessageType, id: string, callback: TSubscriptionCallback) =>
      subscribeFactory(subscriptions.current)(type, id, callback),
    []
  );

  const unsubscribe = useCallback(
    (type: TSignalrMessageType, id: string) => unsubscribeFactory(subscriptions.current)(type, id),
    []
  );

  const contextValue = useMemo(() => {
    return {
      connectionHubInstance: connectionHubInstance,
      subscribe,
      unsubscribe,
    };
  }, [subscribe, unsubscribe]);

  useEffect(() => {
    if (isAuthenticated) {
      // connection initialization
      connectionHubInstance.current = new HubConnectionBuilder()
        .withUrl(`${window._STENN_.API_PUSHSENDER_PATH}/NotificationHub`, {
          accessTokenFactory: async () => {
            const token = await getAccessTokenSilently();
            if (!token) throw new AccessTokenError();
            return token;
          },
        })
        .withAutomaticReconnect()
        .build();

      connectionHubInstance.current.on('ReceiveNewNotification', ({ type, data }: ISignalrResponse) => {
        const typeSubscriptions = subscriptions.current?.get(type);
        if (typeSubscriptions) {
          typeSubscriptions.forEach((callback) => callback(data));
        }
      });

      connectionHubInstance.current.start().catch((error) => {
        if (error instanceof AccessTokenError) return;
        captureException(error);
      });
    }
    return () => {
      connectionHubInstance.current?.stop();
      connectionHubInstance.current = null;
    };
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    return () => {
      connectionHubInstance.current?.stop();
    };
  }, []);

  return <SignalrContext.Provider value={contextValue as ISignalrContext}>{children}</SignalrContext.Provider>;
};

const MemoSignalrProvider = memo(SignalrProvider);
export default MemoSignalrProvider;
