import { FC } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { ConfigProvider, ToastManager } from '@stenngroup/ui-kit';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import SignalrProvider from './signalr/SignalrProvider';
import { ColorMode, ResetStylesNew } from '@stenngroup/theme';

import * as Sentry from '@sentry/react';
import { CriticalErrorLayout } from './components/Layout/CriticalError';

import { EThemeMode } from './common/context/ThemeModeSettingContext/types';
import { useThemeMode } from './common/context/ThemeModeSettingContext/hooks/useThemeMode/useThemeMode';
import { useGetProfile } from './api/profle/useGetProfile';
import { useTrackPageView } from './hooks/useTrackPageView';
import { CircularProgress, Stack } from '@mui/material';
import { AnalyticsProvider } from './context/AnalyticsProvider';
import { QueryClientProviderWrapper } from './context/QueryClientProviderWrapper';
import { useSetAnalitycsIdentity } from '@rbf/hooks/useSetAnalitycsIdentity';
import { AuthProviderWrapper } from './context/AuthProviderWrapper';
import { useAuth } from '@stenngroup/auth0-sdk';
import { CookiesConsentWrapper } from './components/CookiesConsentWrapper';
import { AcceptPoliciesContainer } from './components/AcceptPoliciesContainer';
import { useSetSentryUser } from './hooks/useSetSentryUser';
import { DevFeatureToggleContainer } from './components/DevFeatureToggleContainer';
import { InactivityDialogContainer } from './components/InactivityDialogContainer';
import { isDevFeatureEnabled } from './components/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from './components/DevFeatureToggleContainer/enums';

const ThemeModeToColorMode: Record<EThemeMode, ColorMode> = {
  [EThemeMode.Dark]: 'dark',
  [EThemeMode.Light]: 'light',
};

const AppInner: FC = () => {
  const { isLoading: isAuthLoading, isAuthenticated } = useAuth();
  const { isFetched: isProfileFetched } = useGetProfile();

  useTrackPageView();
  useSetAnalitycsIdentity();
  useSetSentryUser();

  if (isAuthLoading || (isAuthenticated && !isProfileFetched)) {
    return (
      <Stack flexGrow={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return <Routes />;
};

const App: FC = () => {
  const themeMode = useThemeMode();

  return (
    <ConfigProvider locale="en" colorMode={ThemeModeToColorMode[themeMode]}>
      <ResetStylesNew />
      <DevFeatureToggleContainer />
      <Sentry.ErrorBoundary fallback={<CriticalErrorLayout />}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastManager>
            <QueryClientProviderWrapper>
              <BrowserRouter>
                <AnalyticsProvider>
                  <AuthProviderWrapper>
                    <SignalrProvider>
                      <AcceptPoliciesContainer />
                      <CookiesConsentWrapper />
                      {isDevFeatureEnabled(EDevFeature.INACTIVITY_TIMEOUT) && <InactivityDialogContainer />}
                      <Stack height="100%" minWidth={320}>
                        <AppInner />
                      </Stack>
                    </SignalrProvider>
                  </AuthProviderWrapper>
                </AnalyticsProvider>
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProviderWrapper>
          </ToastManager>
        </LocalizationProvider>
      </Sentry.ErrorBoundary>
    </ConfigProvider>
  );
};

export default App;
