import { ELocalStorageKey } from '@rbf/storage-keys';
import { EDevFeature } from '../enums';

/**
 *
 * @description
 * Use this function to define the default feature flags.
 */
const getDefaultFeatureFlags = (): Partial<Record<EDevFeature, boolean>> => ({
  [EDevFeature.ONBOARDING_LAYOUT_RESTYLE]: true,
});

export const getDevFeaturesFromStorage = (): Partial<Record<EDevFeature, boolean>> => {
  const jsonString = localStorage.getItem(ELocalStorageKey.DevFeatures);
  if (!jsonString) return getDefaultFeatureFlags();
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return {};
  }
};
