import { FC, ReactElement } from 'react';

import { Stack } from '@mui/material';
import { SideBar } from './SideBar';

export const Layout: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <Stack
      flexGrow={1}
      direction="row"
      position="relative"
      sx={{
        backgroundColor: (t) => t.palette.background.level1,
      }}
    >
      <SideBar />
      <Stack component="main" flexGrow={1} flexShrink={1} flexBasis="auto" minWidth={0} maxWidth="100%" marginLeft={-2}>
        {children}
      </Stack>
    </Stack>
  );
};
