import { useAnalytics } from '@rbf/context/AnalyticsProvider/hooks/useAnalytics';
import { sendPageViewToGA } from '@rbf/context/AnalyticsProvider/utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useTrackPageView = (): void => {
  const location = useLocation();
  const tracking = useAnalytics();

  useEffect(() => {
    if (!tracking) return;

    sendPageViewToGA(window.location.href, tracking);
  }, [location.pathname, tracking]);
};
