import { FC, lazy, useEffect } from 'react';
import { OFFER_ID_PARAM } from '../../constants';
import { EGraphQLService } from '@rbf/core/graphql/enums';
import { Navigate, useParams } from 'react-router-dom';
// import { useGraphQLClient } from '@rbf/core/graphql/hooks/useGraphQLClient';
import { useQuery } from 'react-query';
import { offerActivationQueryDocument } from '../../graphql-documents/offerActivationQueryDocument.rbf';
import { CircularProgress, Stack } from '@mui/material';
import {
  ApplicationStateModel,
  OfferActivationQuery,
  OfferStateModel,
  PaymentCadenceModel,
  StennWalletActivationStateModel,
} from '@rbf/__generated__/graphql/rbf/graphql';
import { createGetQueryKey } from '@rbf/core/graphql/helpers/createGetQueryKey';
import { routes } from '@rbf/routes/config';
import { captureException } from '@sentry/react';

const OfferAcceptPage = lazy(() => import('./pages/OfferAcceptPage'));
const OfferVerificationPage = lazy(() => import('./pages/OfferVerificationPage'));
const OfferWalletOpeningPage = lazy(() => import('./pages/OfferWalletOpeningPage'));

export const OfferPage: FC = () => {
  const { id } = useParams() as { [OFFER_ID_PARAM]: string };
  // const rbfGraphQLClient = useGraphQLClient(EGraphQLService.rbf);

  const { data: offerActivation, refetch: refetchOfferActivation } = useQuery(
    [createGetQueryKey(EGraphQLService.rbf)(offerActivationQueryDocument)],
    // ({ signal }) =>
    //   rbfGraphQLClient.request({ document: offerActivationQueryDocument, signal, variables: { offerId: id } }),
    (): Promise<OfferActivationQuery> =>
      Promise.resolve({
        offerActivation: {
          __typename: 'FboOfferActivationModel',
          applicationId: '123',
          offer: {
            id,
            offerTerms: {
              amount: 100034,
              feesRate: 0.1,
              paymentCadence: PaymentCadenceModel.Monthly,
              daysValidAfterIssue: 30,
              repaymentRate: 0.1,
              tenor: 12,
              expiresAt: new Date().toISOString(),
              maxPaymentAmount: 1000,
            },
            requiresStennWallet: true,
            state: OfferStateModel.Accepted,
            closeReason: null,
          },
          state: ApplicationStateModel.Approved,
          stennWalletActivation: {
            agreedToDataUsage: true,
            agreedToTermsAndConditions: true,
            businessVerification: {
              submitted: false,
              mccIndustryCode: 'asd',
              certificateOfIncorporation: {
                fileId: 'asd',
                fileName: 'asdsad',
              },
            },
            verifiableBusinessPersons: [],
            state: StennWalletActivationStateModel.Draft,
          },
        },
      } as OfferActivationQuery),
    {
      refetchOnMount: true,
      select: (res) => res.offerActivation,
    }
  );

  useEffect(() => {
    if (!offerActivation) return;
    if (!offerActivation.offer.offerTerms.expiresAt) {
      captureException(new Error('Offer expiration date is not set'));
      return;
    }
    const offerExpirationDate = new Date(offerActivation.offer.offerTerms.expiresAt);

    const interval = window.setInterval(() => {
      if (new Date() >= new Date(offerExpirationDate)) {
        window.clearInterval(interval);
        refetchOfferActivation();
      }
    }, 1000);
    return () => window.clearInterval(interval);
  }, [offerActivation, refetchOfferActivation]);

  if (!offerActivation) {
    return (
      <Stack flexGrow={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  const typename = offerActivation.__typename;
  if (!typename) throw new Error('__typename must be defined');
  if (typename === 'FboOfferActivationModel') {
    // @TODO remove this check, should be non-nullable soon
    if (!offerActivation.stennWalletActivation) throw new Error('stennWalletActivation must be defined');
    const state = offerActivation.stennWalletActivation.state;
    switch (state) {
      case StennWalletActivationStateModel.Draft:
        return <OfferVerificationPage fboOfferActivation={offerActivation} />;
      case StennWalletActivationStateModel.Declined:
        // for now it means that a user can't go any further
        return <Navigate to={routes.onboarding.fundingRejected.path} />;
      case StennWalletActivationStateModel.Submitted:
        // return We’re opening your Stenn Wallet account page
        return <OfferWalletOpeningPage shouldShownRequestInformationDialog={false} />;
      case StennWalletActivationStateModel.RequestedData:
        // return We’re opening your Stenn Wallet account page (and show "We need a little more information" dialog)
        return <OfferWalletOpeningPage shouldShownRequestInformationDialog />;
      case StennWalletActivationStateModel.Opened:
        return <OfferAcceptPage />;

      default: {
        const unhandledState: never = state;
        captureException(new Error(`Unhandled offer state: "${unhandledState}"`));
      }
    }
  } else if (typename === 'RbfOfferActivationModel') {
    return <OfferAcceptPage />;
  } else {
    const unhandledType: never = typename;
    captureException(new Error(`Unhandled offer activation type: "${unhandledType}"`));
    return null;
  }
};
