import styled from 'styled-components';
import { StennLogo } from '@stenngroup/ui-kit';
import { EListType } from './types';
import { Typography } from '@mui/material';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f9f9fb;
  /* background-color: ${({ theme }): string => theme.palette.grey[200]}; */
`;

export const Wrapper = styled.div<{ overflow?: string }>`
  display: inline-flex;
  position: relative;
  left: 50%;
  overflow-x: ${({ overflow }): string => overflow || 'hidden'};
  align-items: flex-start;
  justify-content: ${({ overflow }): string => (overflow ? 'unset' : 'center')};
  margin: 1rem auto;
  padding-top: 96px;
  width: 100%;
  max-width: 100%;
  min-height: calc(100% - 2rem);
  transform: translateX(-50%);
`;

export const Content = styled.div<{ width?: string }>`
  position: relative;
  box-sizing: ${({ width }): string => (width ? 'border-box' : 'content-box')};
  margin: 0 auto;
  padding: 50px 52px 60px;
  width: ${({ width }): string => width || '687px'};
  border-radius: 8px;
  background-color: ${({ theme }): string => theme.palette.common.white};
`;

export const FullScreenModalLogo = styled(StennLogo)`
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 27px;
  left: 30px;
  width: auto;
`;

export const MainList = styled.ol`
  margin: 16px 0;
  padding-left: 0;
  list-style-type: none;
  counter-reset: listItems;
`;

export const ListItem = styled.li<{ listType?: EListType }>`
  position: relative;
  margin: 16px 0;

  &::before {
    display: inline;
    font-size: 16px;
    font-weight: bold;
    counter-increment: listItems;
    font-family: Inter, sans-serif;
    content: ${({ listType }): string =>
      listType === EListType.LowerLatin // condition for the correct numbering of paragraphs with the value lower-latin
        ? `counter(listItems, ${EListType.LowerLatin}) ". "`
        : `counters(listItems, ".", ${listType}) ". "`};
  }

  li:only-child::before {
    display: none;
  }

  p {
    margin-bottom: 22px;
  }
`;

export const List = styled(MainList)<{ listType?: EListType }>`
  padding-left: 30px;
`;

export const Title = styled(Typography)`
  display: inline-block;
  font-size: 16px;
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const TitleListItem = styled(Title)`
  margin: 0;
  display: inline;
`;
