import { FC, ReactNode, useCallback } from 'react';
import { AuthProvider } from '@stenngroup/auth0-sdk';
import { createAuthConfig } from '@rbf/context/AuthProviderWrapper/helpers/createAuthConfig';
import { useQueryClient } from 'react-query';
import { ELocalStorageKey } from '@rbf/storage-keys';

const auth0Config = createAuthConfig();

export const AuthProviderWrapper: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const queryClient = useQueryClient();

  const handleBeforeLogout = useCallback(async () => {
    queryClient.getQueryCache().clear();
    await queryClient.cancelQueries();
  }, [queryClient]);

  return (
    <AuthProvider config={auth0Config} onBeforeLogout={handleBeforeLogout} localStorageKey={ELocalStorageKey.AuthData}>
      {children}
    </AuthProvider>
  );
};
