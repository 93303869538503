import { IGraphQLError } from '@rbf/core/graphql/helpers/isGraphQLEndpointError/types';
import { IGraphQLErrorFingerprintData } from './types';

/**
 * https://github.com/jasonkuhrt/graphql-request/blob/5fe1cea6ea90bea72865a0097f424bf16c0ca3a7/src/raw/classes/ClientError.ts#L9-L11
 * just like default graphql-request error, but without variables
 */
const formatFingerprintdata = (graphqlError: IGraphQLError): IGraphQLErrorFingerprintData => {
  return {
    response: graphqlError.response,
    request: {
      query: graphqlError.request.query,
    },
  };
};

export const createFingerprint = (graphqlError: IGraphQLError): string =>
  JSON.stringify(formatFingerprintdata(graphqlError));
