import { useMediaQuery } from '@mui/material';
import { useThemeModeSetting } from '@rbf/common/context/ThemeModeSettingContext/hooks/useThemeModeSetting/useThemeModeSetting';
import { EThemeMode } from '@rbf/common/context/ThemeModeSettingContext/types';
import { determineThemeToApply } from './helpers/determineThemeToApply/determineThemeToApply';

export const useThemeMode = (): EThemeMode => {
  const { modeSetting } = useThemeModeSetting();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return determineThemeToApply(modeSetting, prefersDarkMode);
};
