import { FC, useEffect, useState } from 'react';
import {
  loadHeapOnce,
  setHeapIdentity,
  setHotjarIdentity,
  updateGTMconsent,
} from '@rbf/context/AnalyticsProvider/utils';
import { useGetProfile } from '@rbf/api/profle/useGetProfile';
import { CookiesConsent } from '@stenngroup/ui-kit';
import { setCookiesConsent, hasCookiesConsent, setCookiesConsentIfGlobalPrivacyPolicySet } from '@stenngroup/utilities';

export const CookiesConsentWrapper: FC = () => {
  const { data: profile } = useGetProfile();
  const [isCookiesConsentWidgetShown, setIsCookiesConsentWidgetShown] = useState(false);

  useEffect(() => {
    setCookiesConsentIfGlobalPrivacyPolicySet(window._STENN_.ENVIRONMENT === 'live', navigator)();
    const hasConsent = hasCookiesConsent(window._STENN_.ENVIRONMENT === 'live')();
    setIsCookiesConsentWidgetShown(!hasConsent);
  }, []);

  if (!isCookiesConsentWidgetShown) return null;

  return (
    <CookiesConsent
      locale="en"
      onSave={(result) => {
        setCookiesConsent(window._STENN_.ENVIRONMENT === 'live')(result);
        const isTrackingEnabled = result.performance;
        updateGTMconsent(isTrackingEnabled);
        if (isTrackingEnabled) {
          loadHeapOnce();
          setHeapIdentity(profile?.userId);
          setHotjarIdentity(profile?.userId);
        }
        setIsCookiesConsentWidgetShown(false);
      }}
    />
  );
};
