import { CookiesStorageService } from './CookiesStorageService';

import Cookies from 'js-cookie';
import { determineStennCookiesDomain } from '@stenngroup/utilities';

export const COOKIE_KEYS = {
  firstLandingUrl: 'rbf_app_first_landing_url',
  firstLandingReferrer: 'rbf_app_first_landing_referrer',
} as const;

const TRACKING_KEYS: readonly [string, string] = [
  COOKIE_KEYS.firstLandingUrl,
  COOKIE_KEYS.firstLandingReferrer,
] as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCookiesTrackingData = (): Record<string, any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trackingData: Record<string, any> = {};

  for (const key of TRACKING_KEYS) {
    const value = CookiesStorageService.getValue(key);

    if (value) {
      trackingData[key] = decodeURIComponent(value);
    }
  }

  return trackingData;
};

export const setTrackingFirstLandingUrl = (): void => {
  const firstLandingUrl = CookiesStorageService.getValue(COOKIE_KEYS.firstLandingUrl);

  if (!firstLandingUrl) {
    const { origin, pathname, search } = window.location;

    // hash is excluded here
    CookiesStorageService.setValue(COOKIE_KEYS.firstLandingUrl, encodeURIComponent(origin + pathname + search));

    // const firstLandingSearchParams = new URLSearchParams(search);
    // CookiesStorageService.setValue('stenn_app_utm_source', firstLandingSearchParams.get('utm_source') || '');
    // CookiesStorageService.setValue('stenn_app_utm_campaign', firstLandingSearchParams.get('utm_campaign') || '');
    // CookiesStorageService.setValue('stenn_app_utm_medium', firstLandingSearchParams.get('utm_medium') || '');
    // CookiesStorageService.setValue('stenn_app_utm_campaign_id', firstLandingSearchParams.get('utm_campaign_id') || '');
    // CookiesStorageService.setValue('stenn_app_utm_term', firstLandingSearchParams.get('utm_term') || '');
    // CookiesStorageService.setValue('stenn_app_utm_content', firstLandingSearchParams.get('utm_content') || '');
    // CookiesStorageService.setValue('stenn_app_gclid', firstLandingSearchParams.get('gclid') || '');
    // CookiesStorageService.setValue('stenn_app_creative_id', firstLandingSearchParams.get('creative_id') || '');
    // CookiesStorageService.setValue('stenn_app_iref', firstLandingSearchParams.get('iref') || '');
  }

  const { referrer } = document;
  if (referrer) {
    Cookies.set(COOKIE_KEYS.firstLandingReferrer, encodeURIComponent(referrer), {
      domain: determineStennCookiesDomain(window._STENN_.ENVIRONMENT === 'live'),
      // 30 minutes
      expires: 1 / 48,
    });
  }
};
