import { FC, lazy, memo, Suspense } from 'react';
// import { DashboardLayout } from '@rbf/components/Layout/Dashboard';
import { Navigate, Outlet, Route, Routes as RouterRoutes } from 'react-router-dom';
import { routes } from './config';
import { withAuthenticated } from './hocs/withAuthenticated/withAuthenticated';
import { withUnauthenticated } from './hocs/withUnauthenticated/withUnauthenticated';
import { CircularProgress, Stack } from '@mui/material';
import { LegalLayout } from '@rbf/components/Layout/Legal';
import OnboardingRoutes from '@rbf/Pages/Onboarding';
import DashboardRoutes from '@rbf/Pages/Dashboard';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { OnboardingLayout } from '@rbf/components/OnboardingLayout';
import { OnboardingLayout as OnboardingLayoutV2 } from '@rbf/components/OnboardingLayoutV2';
import { withAutoNavigation } from './hocs/withAutoNavigation/withAutoNavigation';
import { withCompletedApplicantInformation } from '@rbf/Pages/Dashboard/hocs/withCompletedApplicantInformation/withCompletedApplicantInformation';
import { isDevFeatureEnabled } from '@rbf/components/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@rbf/components/DevFeatureToggleContainer/enums';

const RouterRoutesWithSentry = withSentryReactRouterV6Routing(RouterRoutes);

const LoginPage = lazy(() => import('@rbf/Pages/Authentication/LoginPageV3'));
const LoginPageV4 = lazy(() => import('@rbf/Pages/Authentication/LoginPageV4'));
const SignUpPage = lazy(() => import('@rbf/Pages/Authentication/SignUpPageV3'));
const SignUpPageV4 = lazy(() => import('@rbf/Pages/Authentication/SignUpPageV4'));
const EmailConfirmationPage = lazy(() => import('@rbf/Pages/Authentication/EmailConfirmationPage'));
const PhoneConfirmationPage = lazy(() => import('@rbf/Pages/Authentication/PhoneConfirmationPage'));
const ForgotPasswordMainPage = lazy(() => import('@rbf/Pages/Authentication/ForgotPasswordV3/steps/Main'));
const ForgotPasswordEmailConfirmationPageV3 = lazy(
  () => import('@rbf/Pages/Authentication/ForgotPasswordV3/steps/EmailConfirmation')
);
const ChangePasswordPage = lazy(() => import('@rbf/Pages/Authentication/ForgotPasswordV3/steps/ChangePassword'));
const PasswordSettedPage = lazy(() => import('@rbf/Pages/Authentication/ForgotPasswordV3/steps/PasswordSetted'));

const InvitationAcceptPage = lazy(() => import('@rbf/Pages/InvitationAcceptPageV3'));
const InvitationAcceptPageV4 = lazy(() => import('@rbf/Pages/InvitationAcceptPageV4'));

// const DashboardPage = () => import('@rbf/Pages/Dashboard');
const LazyACHIInfoPage = lazy(() => import('@rbf/Pages/ACHIInfo'));

const CompleteInvitedApplicantInformation = lazy(() => import('@rbf/Pages/CompleteInvitedApplicantInformationV3'));
const CompleteInvitedApplicantInformationV4 = lazy(() => import('@rbf/Pages/CompleteInvitedApplicantInformationV4'));

const DocusignReturnPage = lazy(() => import('@rbf/Pages/DocusignReturn'));
const PlatformReturnPage = lazy(() => import('@rbf/Pages/PlatformReturn'));

const RootRoute = withAuthenticated(withAutoNavigation(() => <Navigate to={routes.onboarding.path} />));

/** Routes that can be accessed only when unauthenticated  */
const UnauthenticatedRouteContainer: FC = withUnauthenticated(() => <Outlet />);
/** Routes that can be accessed only when authenticated */
const AuthenticatedRouteContainer: FC = withAuthenticated(() => <Outlet />);

const AutoNavigationRouteContainer: FC = withAutoNavigation(() => <Outlet />);

const CompletedApplicantInformationRoutesContainer: FC = withCompletedApplicantInformation(() => <Outlet />, true);
const IncompletedApplicantInformationRoutesContainer: FC = withCompletedApplicantInformation(() => <Outlet />, false);

/** Public routes, can be accessed when authenticated and unauthenticated */
const PublicRouteContainer: FC = () => <Outlet />;

const Layout = isDevFeatureEnabled(EDevFeature.ONBOARDING_LAYOUT_RESTYLE) ? OnboardingLayoutV2 : OnboardingLayout;
/** Routes with authentication layout (left side — form, right side — background image) */
const OnboardingRoutesLayout: FC<{ isLogoutButtonShown?: boolean }> = ({ isLogoutButtonShown = false }) => (
  <Layout isLogoutButtonShown={isLogoutButtonShown}>
    <Suspense
      fallback={
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      }
    >
      <Outlet />
    </Suspense>
  </Layout>
);

const LegalRoutesLayout: FC = () => (
  <LegalLayout>
    <Outlet />
  </LegalLayout>
);

const RouteContainer: FC = () => (
  <Suspense fallback={null}>
    <Outlet />
  </Suspense>
);

export const Routes = memo(function Routes() {
  return (
    <RouterRoutesWithSentry>
      {/* Redirects to main page when authenticated and to login otherwise */}
      <Route path={routes.root.path} element={<RootRoute />} />

      <Route element={<RouteContainer />}>
        {/* Routes that can be accessed only when unauthenticated  */}
        <Route element={<UnauthenticatedRouteContainer />}>
          <Route element={<OnboardingRoutesLayout />}>
            <Route
              path={routes.auth.login.path}
              element={isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) ? <LoginPageV4 /> : <LoginPage />}
            />
            <Route
              path={routes.auth.signUp.path}
              element={isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) ? <SignUpPageV4 /> : <SignUpPage />}
            />
            <Route path={routes.auth.forgotPassword.passwordSetted.path} element={<PasswordSettedPage />} />
            <Route
              path={routes.auth.forgotPassword.emailConfirmation.path}
              element={<ForgotPasswordEmailConfirmationPageV3 />}
            />
            <Route path={routes.auth.forgotPassword.changePassword.path} element={<ChangePasswordPage />} />
            <Route path={routes.auth.forgotPassword.path} element={<ForgotPasswordMainPage />} />
            <Route
              path={routes.invitation.accept.path}
              element={
                isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) ? <InvitationAcceptPageV4 /> : <InvitationAcceptPage />
              }
            />
          </Route>
        </Route>

        {/* Routes that can be accessed only when authenticated */}
        <Route element={<AuthenticatedRouteContainer />}>
          <Route element={<AutoNavigationRouteContainer />}>
            <Route element={<OnboardingRoutesLayout isLogoutButtonShown />}>
              <Route path={routes.auth.emailConfirmation.path} element={<EmailConfirmationPage />} />
              <Route
                path={routes.auth.phoneConfirmation.path}
                element={isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) ? <PhoneConfirmationPage /> : null}
              />
            </Route>

            <Route path={routes.onboarding.path + '/*'} element={<OnboardingRoutes />} />
            <Route element={<CompletedApplicantInformationRoutesContainer />}>
              <Route path={routes.dashboard.path + '/*'} element={<DashboardRoutes />} />
            </Route>
          </Route>

          <Route element={<IncompletedApplicantInformationRoutesContainer />}>
            <Route element={<OnboardingRoutesLayout />}>
              <Route
                path={routes.invitation.completeApplicantInformation.path}
                element={
                  isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) ? (
                    <CompleteInvitedApplicantInformationV4 />
                  ) : (
                    <CompleteInvitedApplicantInformation />
                  )
                }
              />
            </Route>
          </Route>

          <Route path={routes.codatReturn.path} element={<PlatformReturnPage />} />
          <Route path={routes.docuSignReturn.path} element={<DocusignReturnPage />} />
        </Route>

        {/* Public routes, can be accessed when authenticated and unauthenticated */}
        <Route element={<PublicRouteContainer />}>
          <Route element={<LegalRoutesLayout />}>
            <Route path={routes.achInfo.path} element={<LazyACHIInfoPage />} />
          </Route>
        </Route>
      </Route>

      {/* Redirects to root / from anywhere, which in turn will redirect to needed page */}
      <Route path="*" element={<Navigate to={routes.root.path} />} />
    </RouterRoutesWithSentry>
  );
});
