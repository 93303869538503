import { ComponentType, FC, ReactElement } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { routes } from '@rbf/routes/config';
import { useGetProfile } from '@rbf/api/profle/useGetProfile';
import { useGraphQLClient } from '@rbf/core/graphql/hooks/useGraphQLClient';
import { EGraphQLService } from '@rbf/core/graphql/enums';
import { useQuery } from 'react-query';
import { createGetQueryKey } from '@rbf/core/graphql/helpers/createGetQueryKey';
import { currentApplicationQueryDocument } from '@rbf/Pages/Onboarding/graphql-documents/currentApplicationQueryDocument.rbf';
import {
  AppApplicationCloseReasonModel,
  AppApplicationStateModel,
  ApplicationStateModel,
} from '@rbf/__generated__/graphql/rbf/graphql';
import { isDevFeatureEnabled } from '@rbf/components/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@rbf/components/DevFeatureToggleContainer/enums';

const STATE_REDIRECTS: Record<ApplicationStateModel, string> = {
  [ApplicationStateModel.Approved]: routes.onboarding.path,
  [ApplicationStateModel.Draft]: routes.onboarding.path,
  [ApplicationStateModel.InProgress]: routes.onboarding.path,
  [ApplicationStateModel.Rejected]: routes.onboarding.path,
  [ApplicationStateModel.RequestedData]: routes.onboarding.path,
  [ApplicationStateModel.Submitted]: routes.onboarding.path,
  [ApplicationStateModel.OfferAccepted]: routes.dashboard.path,
};

const STATE_REDIRECTS_FBO: Record<Exclude<AppApplicationStateModel, AppApplicationStateModel.Closed>, string> = {
  [AppApplicationStateModel.Approved]: routes.onboarding.path,
  [AppApplicationStateModel.Draft]: routes.onboarding.path,
  [AppApplicationStateModel.InProgress]: routes.onboarding.path,
  [AppApplicationStateModel.RequestedData]: routes.onboarding.path,
  [AppApplicationStateModel.Submitted]: routes.onboarding.path,
};

export const withAutoNavigation = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithAutoNavigation(props: P): ReactElement | null {
    const { data: profile } = useGetProfile();
    const { pathname } = useLocation();

    const rbfGraphQLClient = useGraphQLClient(EGraphQLService.rbf);
    const isCurrentApplicationQueryEnabled = !!profile?.emailIsConfirmed;
    const { data: currentApplication, isFetched: isCurrentApplicationFetched } = useQuery(
      [createGetQueryKey(EGraphQLService.rbf)(currentApplicationQueryDocument)],
      ({ signal }) => rbfGraphQLClient.request({ document: currentApplicationQueryDocument, signal }),
      {
        enabled: isCurrentApplicationQueryEnabled,
        refetchOnMount: true,
        select: (res) => res.currentApplication,
      }
    );

    const isCurrentApplicationFetchedOrDisabled = isCurrentApplicationQueryEnabled ? isCurrentApplicationFetched : true;
    if (!profile || !isCurrentApplicationFetchedOrDisabled) return null;

    const emailIsConfirmed = profile.emailIsConfirmed;
    const phoneNumberIsConfirmed = profile.phoneNumberIsConfirmed;

    if (isDevFeatureEnabled(EDevFeature.DISABLE_AUTO_ROUTE)) {
      return <Component {...props} />;
    }

    if (!emailIsConfirmed) {
      if (!pathname.includes(routes.auth.emailConfirmation.path)) {
        return <Navigate to={routes.auth.emailConfirmation.path} />;
      }
    } else if (isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING) && !phoneNumberIsConfirmed) {
      // invited users doesn't have phone number on start
      if (!pathname.includes(routes.auth.phoneConfirmation.path)) {
        return <Navigate to={routes.auth.phoneConfirmation.path} />;
      }
    } else {
      let redirectPath: string = routes.onboarding.path;

      if (currentApplication) {
        if (isDevFeatureEnabled(EDevFeature.FBO_ONBOARDING)) {
          if (currentApplication.stateV2 === AppApplicationStateModel.Closed) {
            if (currentApplication.closeReason === AppApplicationCloseReasonModel.Expired) {
              redirectPath = routes.dashboard.path;
            } else {
              redirectPath = routes.onboarding.path;
            }
          } else {
            redirectPath = STATE_REDIRECTS_FBO[currentApplication.stateV2];
          }
        } else {
          redirectPath = STATE_REDIRECTS[currentApplication.state];
        }
      }

      if (redirectPath && !pathname.includes(redirectPath)) {
        return <Navigate to={redirectPath} replace />;
      }
    }

    return <Component {...props} />;
  };
};
