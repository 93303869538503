import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { routes } from '@rbf/routes/config';
import { FC, lazy, memo, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { SettingsPageLayout } from './components/SettingsPageLayout';

const AccountSettingsPage = lazy(() => import('./pages/AccountSettingsPage'));

const RouteContainer: FC = () => (
  <SettingsPageLayout>
    <Suspense
      fallback={
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      }
    >
      <Outlet />
    </Suspense>
  </SettingsPageLayout>
);

const getPath = (path: string): string => path.replace(routes.dashboard.settings.path, '');

const DashboardSettingsRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<RouteContainer />}>
        <Route path={getPath(routes.dashboard.settings.account.path)} element={<AccountSettingsPage />} />
      </Route>

      <Route path="*" element={<Navigate to={routes.dashboard.settings.account.path} />} />
    </Routes>
  );
};

const MemoDashboardSettingsRoutes = memo(DashboardSettingsRoutes);
export default MemoDashboardSettingsRoutes;
