(function (gtmId) {
    if (!gtmId) {
        return;
    }

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    document.body.insertBefore(noscript, document.body.childNodes[0]);
})(window._STENN_.GTM_ID);
