import { CircularProgress, Stack } from '@mui/material';
import { FC, lazy, Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { routes } from '@rbf/routes/config';
import { OFFER_ID_PARAM } from './constants';
import { OfferPage } from './pages/OfferPage';

const OffersPage = lazy(() => import('./pages/OffersPage'));

const RouteContainer: FC = () => (
  <Suspense
    fallback={
      <Stack flexGrow={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    }
  >
    <Outlet />
  </Suspense>
);

const getPath = (path: string): string => path.replace(routes.onboarding.offers.path, '');

export const OfferRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<RouteContainer />}>
        <Route index element={<OffersPage />} />
        <Route element={<OfferPage />} path={getPath(routes.onboarding.offers.path + `/:${OFFER_ID_PARAM}`)} />
      </Route>
    </Routes>
  );
};
