import { lazy, memo, FC, Suspense } from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { routes } from '@rbf/routes/config';
import { CircularProgress, Stack } from '@mui/material';
import { Layout } from './components/Layout';
import DashboardSettingsRoutes from './DashboardSettingsRoutes';
import { TRANSACTION_ID_PARAM } from './pages/TransactionPage/constants';
import { isDevFeatureEnabled } from '@rbf/components/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@rbf/components/DevFeatureToggleContainer/enums';

const DashboardMainPage = lazy(() => import('./pages/DashboardMainPage'));
const DashboardConnectionsPage = lazy(() => import('./pages/ConnectionsPage'));
const DocumentsPage = lazy(() => import('./pages/DocumentsPage'));
const TeamMembersPage = lazy(() => import('./pages/TeamMembersPage'));
const TransactionPage = lazy(() => import('./pages/TransactionPage'));
const WalletPage = lazy(() => import('./pages/WalletPage'));

const RouteContainer: FC = () => (
  <Layout>
    <Suspense
      fallback={
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      }
    >
      <Outlet />
    </Suspense>
  </Layout>
);

const getPath = (path: string): string => path.replace(routes.dashboard.path, '');

const DashboardRoutes = memo(function DashboardRoutes() {
  return (
    <Routes>
      <Route element={<RouteContainer />}>
        <Route index element={<DashboardMainPage />} />
        <Route
          path={getPath(routes.dashboard.transactions.path + `/:${TRANSACTION_ID_PARAM}`)}
          element={<TransactionPage />}
        />
        <Route path={getPath(routes.dashboard.connections.path)} element={<DashboardConnectionsPage />} />
        <Route path={getPath(routes.dashboard.settings.path) + '/*'} element={<DashboardSettingsRoutes />} />
        <Route path={getPath(routes.dashboard.documents.path)} element={<DocumentsPage />} />
        <Route path={getPath(routes.dashboard.teamMembers.path)} element={<TeamMembersPage />} />
        <Route
          path={getPath(routes.dashboard.wallet.path)}
          element={
            isDevFeatureEnabled(EDevFeature.FBO_DASHBOAD_WALLET) ? (
              <WalletPage />
            ) : (
              <Navigate to={routes.dashboard.path} />
            )
          }
        />
      </Route>

      <Route path="*" element={<Navigate to={routes.dashboard.path} />} />
    </Routes>
  );
});

export default DashboardRoutes;
