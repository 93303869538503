/**
 * Wrapper for any error that can be rejected by the "fetch"
 * Not to be confused with a backend error response
 *
 * @example
 * let res: Response;
 * try {
 *   res = await fetch(url);
 * } catch (error) {
 *   throw new FetchError(error as Error);
 * }
 *
 * if (!res.ok) {
 *   // backend error response
 * }
 *
 * // somewhere above in the code
 * onError: (err: unknown) => {
 *  if (err instanceof FetchError) {
 *    toast.showError('An error occurred while the system was running.');
 *  }
 * }
 */
export class FetchError extends Error {
  constructor(readonly fetchError: Error) {
    super(`Fetch error: "${fetchError.message}"`);
  }
}
