import { useEffect } from 'react';
import { useGetProfile } from '@rbf/api/profle/useGetProfile';
import { setHeapIdentityIfLoaded, setHotjarIdentity } from '@rbf/context/AnalyticsProvider/utils';

export const useSetAnalitycsIdentity = (): void => {
  const { data: profile } = useGetProfile();

  useEffect(() => {
    setHeapIdentityIfLoaded(profile?.userId);
    setHotjarIdentity(profile?.userId);
  }, [profile?.userId]);
};
