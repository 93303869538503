import { useMemo } from 'react';

import { GraphQLClient } from 'graphql-request';

import { useAuth } from '@stenngroup/auth0-sdk';
import { graphQLUrls } from '@rbf/core/graphql/graphQLUrls';
import { EGraphQLService } from '@rbf/core/graphql/enums';
import { APP_VERSION } from '@rbf/constants';

export const useGraphQLClient = (service: EGraphQLService): GraphQLClient => {
  const url = graphQLUrls[service];

  const { getAccessTokenSilently } = useAuth();

  const client = useMemo(
    () =>
      new GraphQLClient(url, {
        // @TODO: fix when this the type RequestExtendedInit will be exported https://github.com/jasonkuhrt/graphql-request/issues/421
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        requestMiddleware: async (req) => {
          const headers: HeadersInit = {
            'X-Page-Referer': window.location.pathname,
            'App-Version': APP_VERSION,
          };
          const token = await getAccessTokenSilently();
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }

          return {
            ...req,
            headers: {
              ...req.headers,
              ...headers,
            },
          };
        },
      }),
    [url, getAccessTokenSilently]
  );

  return client;
};
